import classes from "./ShareItinerary.module.css";
import Button from "src/components/UI/Button/Button";
import { Fragment, useEffect, useState } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "src/components/shared/modal/Modal/Modal";
import DesktopModal from "src/components/shared/modal/Modal/DesktopModal";

import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { SHARE_VENDOR } from "src/constants";
import {
  EmailIcon,
  IOSMessagesIcon,
  MessengerIcon,
  TelegramIcon,
  ViberIcon,
  WhatsAppIcon,
} from "src/components/UI/ShareVendorIcon/ShareVendorIcon";

function ShareButtonContent({ vendor }) {
  const { stringRes } = useLocaleContext();
  let icon, name;
  if (vendor === SHARE_VENDOR.email) {
    icon = <EmailIcon />;
    name = stringRes["share.modal.option.email"];
  } else if (vendor === SHARE_VENDOR.whatsapp) {
    icon = <WhatsAppIcon />;
    name = stringRes["share.modal.option.whatsapp"];
  } else if (vendor === SHARE_VENDOR.messenger) {
    icon = <MessengerIcon />;
    name = stringRes["share.modal.option.messenger"];
  } else if (vendor === SHARE_VENDOR.iosMessages) {
    icon = <IOSMessagesIcon color="white" />;
    name = stringRes["share.modal.option.iosMessages"];
  } else if (vendor === SHARE_VENDOR.telegram) {
    icon = <TelegramIcon color="white" />;
    name = stringRes["share.modal.option.telegram"];
  } else if (vendor === SHARE_VENDOR.viber) {
    icon = <ViberIcon color="white" />;
    name = stringRes["share.modal.option.viber"];
  }

  return (
    <button className={classes.shareVendor}>
      {icon}
      <span>{name}</span>
    </button>
  );
}

function ShareItinerary({ onClose, onSelectOption, onCopy, state }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  //TODO: generate or get from data
  const [shareLink, setShareLink] = useState("https://avion.lv/u/testlink");
  const [copyCommands, setCopyCommands] = useState(0);

  const onCopyHandler = () => {
    navigator.clipboard.writeText(shareLink);
    setCopyCommands((prev) => prev + 1);
  };

  useEffect(() => {
    if (copyCommands > 0) {
      const timerId = setTimeout(() => setCopyCommands(0), 7000);
      return () => clearTimeout(timerId);
    }
  }, [copyCommands]);

  const innerContent = (
    <Fragment>
      <h1>{stringRes["share.modal.title"]}</h1>
      {isMobile && (
        <p className={classes.description}>{stringRes["share.modal.description"]}</p>
      )}
      <div className={classes.directLink}>
        <label htmlFor="itinerary-link">
          <input type="text" id="itinerary-link" value={shareLink} readOnly />
        </label>
        <Button
          secondary={copyCommands > 0}
          onClick={onCopyHandler}
          name={
            copyCommands > 0
              ? stringRes["share.modal.copied"]
              : stringRes["share.modal.copy.button"]
          }
        />
      </div>
      <ul className={classes.buttonList}>
        <li onClick={() => onSelectOption(SHARE_VENDOR.whatsapp)}>
          <ShareButtonContent vendor={SHARE_VENDOR.whatsapp} />
        </li>
        <li onClick={() => onSelectOption(SHARE_VENDOR.messenger)}>
          <ShareButtonContent vendor={SHARE_VENDOR.messenger} />
        </li>
        <li onClick={() => onSelectOption(SHARE_VENDOR.iosMessages)}>
          <ShareButtonContent vendor={SHARE_VENDOR.iosMessages} />
        </li>
        <li onClick={() => onSelectOption(SHARE_VENDOR.telegram)}>
          <ShareButtonContent vendor={SHARE_VENDOR.telegram} />
        </li>
        <li onClick={() => onSelectOption(SHARE_VENDOR.viber)}>
          <ShareButtonContent vendor={SHARE_VENDOR.viber} />
        </li>
        <li onClick={() => onSelectOption(SHARE_VENDOR.email)}>
          <ShareButtonContent vendor={SHARE_VENDOR.email} />
        </li>
      </ul>
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color={"var(--ui-5)"} />
          </button>
          {innerContent}
        </div>
      </DesktopModal>
    );
  }
}

export default ShareItinerary;
