import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import {
  ERROR_MESSAGES,
  ERROR_STATUS_MESSAGE,
  TRANSALTED_STRINGS,
} from "src/assets/lang/errorMessages";
import { useBookingFlow } from "src/context/booking-flow-context";
import { useGlobalLoading } from "src/context/loading-context";
import { bookingActions } from "src/store/booking";
import {
  getSelectedLocale,
  markSessionLocked,
  markSessionReloaded,
} from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import BookingLayout from "./BookingLayout";
import { ErrorComponent } from "./ErrorBoundary";
import { BOOKING } from "src/constants/booking";
import useWebSocket, { ReadyState } from "react-use-websocket";

const WS_API_URL = `wss://${window.SERVER_DATA.REACT_APP_API_URL}/booking/websocket`;

function BookingLayoutWrapper() {
  const { setLoading } = useGlobalLoading();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [connectionError, setConnectionError] = useState(null);
  const loaderData = useRouteLoaderData("bookingRouteController");
  const { bookingStartPage } = useBookingFlow();

  const doReconnect = useRef(true);

  /** SETUP WEBSOCKET METHODS */
  const onMessage = useCallback(
    (event) => {
      if(event.data === "pong") return;
      const nextEvent = JSON.parse(event.data);
      const time = new Date();
      customLog(
        `booking websocket: adding event: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
      );
      customLog(
        event.currentTarget.url + "%c 📩 Receiving websocket data: 👇👇👇\n\n",
        "color: #41dcc1;"
      );
      customLog(nextEvent);
      if (nextEvent.passenger_id && nextEvent.original_id) {
        dispatch(
          bookingActions.setPassengerServerIdentifier({
            id: nextEvent.original_id,
            passenger_id: nextEvent.passenger_id,
          })
        );
      } else if (nextEvent.command === BOOKING.CANCEL_ORDER) {
        navigate("/booking/cancelled/", {
          state: { id: loaderData.correlationID, source: "booking" },
        });
      }
    },
    [dispatch, navigate, loaderData.correlationID]
  );

  const onError = useCallback(() => {
    const time = new Date();
    customLog(
      `booking websocket Error: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
    );
    const lang = getSelectedLocale()?.split("_")[0] || "en";
    setConnectionError({
      errorMessage: ERROR_MESSAGES[lang].booking,
      statusText: ERROR_STATUS_MESSAGE[lang].booking,
      buttonText: TRANSALTED_STRINGS[lang].homepage,
    });
    setLoading(false);
  }, [setLoading, setConnectionError]);

  const onDisconnect = useCallback(() => {
    const time = new Date();
    customLog(
      `booking websocket DISconnected: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
    );
  }, []);

  const onConnect = useCallback(() => {
    const time = new Date();
    customLog(
      `booking websocket connected: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
    );
    markSessionLocked();
    markSessionReloaded();
    if (connectionError) navigate(".");
    setConnectionError(null);
  }, []);

  const pageUnmounted = useRef(false);
  useEffect(() => {
    return () => (pageUnmounted.current = true);
  }, []);

  const { sendJsonMessage: send, readyState } = useWebSocket(
    `${WS_API_URL}/${loaderData.correlationID}`,
    {
      onClose: onDisconnect,
      onOpen: onConnect,
      onMessage: onMessage,
      onError: onError,
      retryOnError: true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      shouldReconnect: (event) => pageUnmounted.current === false && doReconnect.current,
      heartbeat: { interval: 25000, timeout: 60000, message: "ping", returnMessage: "pong" },
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  const isConnected = connectionStatus === "Open";

  const sendWrapper = useCallback(
    (data) => {
      customLog(
        loaderData.correlationID +
          "%c ✉️ Sending websocket data: 👇👇👇\n\n" +
          JSON.stringify(data, null, 2),
        "color: #41dcc1;"
      );
      send({ ...data, visibleToUser: true });
    },
    [send, loaderData.correlationID]
  );
  /** END SETUP WEBSOCKET METHODS */
  useEffect(() => {
    setLoading(!(isConnected && bookingStartPage), 0.5);
  }, [setLoading, isConnected, bookingStartPage]);

  if (connectionError) {
    return (
      <ErrorComponent
        errorHeader={connectionError.statusText}
        errorMessages={connectionError.errorMessage}
        buttonText={connectionError.buttonText}
      />
    );
  }

  if (bookingStartPage) {
    return <BookingLayout send={sendWrapper} isConnected={isConnected} />;
  }
}

export default BookingLayoutWrapper;
