import { StopFilter } from "../StopFilter/StopFilter";
import { DurationFilter } from "../DurationFilter/DurationFilter";
import { TimeFilter } from "../TimeFilter/TimeFilter";
import { PriceFilter } from "../PriceFilter/PriceFilter";
import AirportsFilter from "../AirportsFilter/AirportsFilter";
import AirlinesFilter from "../AirlinesFilter/AirlinesFilter";
import { BagsFilter } from "../BagsFilter/BagsFilter";
import classes from "./ExpandingFilterList.module.css";

import { useReducer } from "react";
import { CollapseArrow, ExpandArrow } from "src/components/UI/Arrow/Arrow";

const listStateReducer = (prevState, action) => {
  if (action.type === "toggle") {
    let newState = [...prevState];
    newState[action.payload.index].expanded = action.payload.value;
    return newState;
  }
};

const expandedDefault = ["bags", "stop"];

export function ExpandingFilterList() {
  const filterItems = [
    ["bags", <BagsFilter />],
    ["stop", <StopFilter />],
    ["duration", <DurationFilter />],
    ["time", <TimeFilter />],
    ["airline", <AirlinesFilter />],
    ["airport", <AirportsFilter />],
    ["price", <PriceFilter />],
  ];

  const [state, dispatch] = useReducer(
    listStateReducer,
    filterItems.map((item) => {
      return {
        name: item[0],
        component: item[1],
        expanded: expandedDefault.includes(item[0]),
      };
    })
  );

  const onToggleHandler = (i) => {
    dispatch({
      type: "toggle",
      payload: { index: i, value: !state[i].expanded },
    });
  };

  return (
    <ul className={`theme-filter-search ${classes.container}`}>
      {state.map((item, i) => (
        <li
          key={item.name}
          className={item.expanded ? classes.expanded : classes.collapsed}
        >
          {item.component}
          <span className={classes.arrow} onClick={() => onToggleHandler(i)}>
            {item.expanded ? <CollapseArrow /> : <ExpandArrow />}
          </span>
        </li>
      ))}
    </ul>
  );
}
