import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useNavigate, useNavigation, useSearchParams } from "react-router-dom";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import DesktopResultsHeader from "src/components/features/results/DesktopResultsHeader/DesktopResultsHeader";
import MobileResultsHeader from "src/components/features/results/MobileResultsHeader/MobileResultsHeader";
import ResultsLeftPanel from "src/components/features/results/ResultsLeftPanel";
import { LoadingResultsList } from "src/components/features/results/ResultsList/LoadingResultsList";
import ResultsMainPanel from "src/components/features/results/ResultsMainPanel";
import {
  NetworkErrorResultsNotFound,
  SearchResultsNotFound,
} from "src/components/features/results/ResultsNotFound/ResultsNotFound";
import {
  LoadingResultsRightPanel,
  ResultsRightPanel,
} from "src/components/features/results/ResultsRightPanel/ResultsRightPanel";
import {
  LoadingResultsSorting,
  ResultsSorting,
} from "src/components/features/results/ResultsSorting/ResultsSorting";
import { useAuth } from "src/context/auth-context";
import { useDeviceContext } from "src/context/device-context";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import { PriceExpiryContext } from "src/context/refresh-price-context";
import { updateTravelPackage } from "src/store/actions";
import { filterSortActions } from "src/store/filter-sort";
import { attachResultTags } from "src/utils/filter-utils";
import { markSearchRefreshRequired, setSessionId } from "src/utils/storage-utils";
import { customLog, parseIntFromString, toTitleCase } from "src/utils/utils";
import { ResultsSummary } from "../components/features/results/ResultsSummary/ResultsSummary";
import { reconcileDuplicates } from "./loaders/SearchResultsLoader";
import useWebSocket from "react-use-websocket";

function getExpiryTimeInSeconds() {
  return Date.now() / 1000 + 30 * 60;
}

const WS_API_URL = `wss://${window.SERVER_DATA.REACT_APP_API_URL}/search/websocket`;
const SIGNIN_ENABLED = window.SERVER_DATA.REACT_APP_ENABLE_RESULTS_SIGNIN === "true";
const RESULTS_STOP_CODE = "search_results_end";
const TIMES_RETRY = 15;
const RECONNECTION_DELAY = 1000; //ms

function SearchResults() {
  const { isMobile } = useDeviceContext();
  const { isAuthenticated, user } = useAuth();
  const { openModal, closeModal } = useModalContext();
  const { searchExpired, setSearchExpiryTimer, clearSearchTimer } =
    useContext(PriceExpiryContext);
  const { requestData } = useLoaderData();
  const dispatch = useDispatch();

  const navigation = useNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const doReconnect = useRef(true);
  const [hasFinishedDataTranfer, setHasFinishedDataTranfer] = useState(true);
  const [showLoadingStates, setShowLoadingStates] = useState(true);
  const [showLoadingBar, setShowLoadingBar] = useState(true);
  const [currentResults, setCurrentResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [metadataMap, setMetadataMap] = useState(new Map());
  const [isNewReq, setIsNewReq] = useState(true);
  const [isWSError, setIsWSError] = useState(false);

  const isReceivingData = useSelector((state) => state.filterSort.isReceivingData);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onMessage = useCallback(
    (nextEvent, send) => {
      console.log("on message ws handler");
      if (nextEvent.data === "pong") return;
      const nextEventJson = JSON.parse(nextEvent.data);
      if (
        nextEventJson.command === RESULTS_STOP_CODE ||
        nextEventJson.message === "All results have been sent."
      ) {
        customLog("END OF SEARCH RESULTS - STOP CODE");
        doReconnect.current = false;
      } else if (nextEventJson.message) {
        customLog("It's a message message");
      } else if (nextEventJson.data && nextEventJson.data.flights) {
        customLog(
          nextEvent.currentTarget.url + "%c 📩 Receiving websocket data: 👇👇👇\n\n",
          "color: #41dcc1;"
        );
        customLog(nextEventJson);
        send({ partNumber: nextEventJson.partNumber });

        const searchRequestKey = nextEventJson.searchRequestKey;
        const companyName = nextEventJson.company_name;
        let nextResultSet = nextEventJson.data.flights;
        for (let i = 0; i < nextResultSet.length; i++) {
          const flightPackage = nextResultSet[i];
          for (let y = 0; y < flightPackage.segments.length; y++) {
            const fl = flightPackage.segments[y];
            fl.arr.city.title = toTitleCase(fl.arr.city.title);
            fl.dep.city.title = toTitleCase(fl.dep.city.title);
          }
        }

        let mergedResults, newMergeKeys;
        setCurrentResults((currentResults) => {
          ({ mergedResults, newMergeKeys } = reconcileDuplicates(
            currentResults,
            nextResultSet
          ));
          mergedResults = attachResultTags(mergedResults);
          return mergedResults;
        });

        // fill in map with mergeKey:searchRequestKey + companyName pairings
        setMetadataMap((prevMap) => {
          let newMap;
          if (prevMap.size === 0) {
            newMap = new Map();
          } else {
            newMap = new Map(prevMap);
          }

          newMergeKeys.forEach((keyToUpdate) =>
            newMap.set(keyToUpdate, { searchRequestKey, companyName })
          );
          return newMap;
        });

        setShowLoadingBar(false);
      }
    },
    [setShowLoadingBar]
  );

  const onDisconnect = useCallback(
    (event, currentUrl) => {
      console.log("on disconnect ws handler");
      console.log(event);
      const { url } = event.target;
      if (url === currentUrl) {
        dispatch(filterSortActions.setIsReceivingData(false));
        setShowLoadingBar(false);
      }
    },
    [dispatch]
  );

  const onConnect = useCallback(
    (event, currentUrl, showLoadingBar) => {
      console.log("on connect ws handler");
      console.log(event);
      const { url } = event.target;
      if (url === currentUrl) {
        setIsWSError(false);
        dispatch(filterSortActions.setIsReceivingData(true));
        setShowLoadingBar(showLoadingBar);
      }
    },
    [dispatch]
  );

  const onError = useCallback(
    (event, currentUrl) => {
      console.log("on error ws handler");
      console.log(event);
      const { url } = event.target;
      if (url === currentUrl) {
        setIsWSError(true);
        onDisconnect();
      }
    },
    [onDisconnect]
  );

  const pageUnmounted = useRef(false);
  useEffect(() => {
    return () => (pageUnmounted.current = true);
  }, []);

  const [servicePath, setServicePath] = useState(null);
  const { sendJsonMessage } = useWebSocket(servicePath, {
    onClose: (event) => onDisconnect(event, servicePath),
    onOpen: (event) => onConnect(event, servicePath, currentResults.length === 0),
    onMessage: (event) => onMessage(event, sendJsonMessage),
    onError: (event) => onError(event, servicePath),
    retryOnError: true,
    reconnectAttempts: TIMES_RETRY,
    reconnectInterval: RECONNECTION_DELAY,
    shouldReconnect: (event) => pageUnmounted.current === false && doReconnect.current,
    heartbeat: { interval: 25000, timeout: 60000, message: "ping", returnMessage: "pong" },
  });

  useEffect(() => {
    const timerID = setTimeout(
      () => setHasFinishedDataTranfer(!isReceivingData),
      !isReceivingData ? RECONNECTION_DELAY + 500 : 0
    );
    return () => clearTimeout(timerID);
  }, [isReceivingData]);

  useEffect(() => {
    customLog(requestData);
    clearSearchTimer();
    closeModal(MODAL_TYPE.refreshPrice);

    setServicePath(requestData.error ? null : `${WS_API_URL}/${requestData.key}`);
    if (requestData.error) {
      dispatch(filterSortActions.setIsReceivingData(false));
    } else {
      setSearchExpiryTimer(getExpiryTimeInSeconds());
    }
  }, [
    requestData,
    setSearchExpiryTimer,
    clearSearchTimer,
    closeModal,
    setServicePath,
    dispatch,
  ]);

  useEffect(() => {
    setIsNewReq(navigation.state === "loading");
    if (navigation.state === "loading") {
      setFilteredResults([]);
      setCurrentResults([]);
      setMetadataMap(new Map());
      setIsWSError(false);
    }
  }, [navigation.state]);

  useEffect(() => {
    setShowLoadingStates(
      navigation.state === "loading" ||
        (currentResults.length === 0 && !hasFinishedDataTranfer)
    );
  }, [navigation.state, currentResults.length, hasFinishedDataTranfer, setShowLoadingStates]);

  const onGoToBooking = (item) => {
    const adults = parseIntFromString(searchParams.get("p_adult"), 1);
    const children = parseIntFromString(searchParams.get("p_child"), 0);
    const infants = parseIntFromString(searchParams.get("p_infant"), 0);

    const travelPackage = item;
    const itemMetadata = metadataMap.get(travelPackage.mergeKey);
    travelPackage.company_name = itemMetadata.companyName; //TODO: use env var?
    travelPackage.searchRequestKey = itemMetadata.searchRequestKey;
    travelPackage.searchQ = searchParams.toString();
    if (isAuthenticated) {
      travelPackage.userId = user.uid;
    }
    dispatch(updateTravelPackage(travelPackage));
    setSessionId("");

    navigate("/booking", {
      state: {
        source: "search-results",
        adults,
        children,
        infants,
      },
    });
  };

  const onRefreshResults = useCallback(() => {
    setIsRefreshing(true);
    const q = searchParams.toString();
    markSearchRefreshRequired();
    navigate("/search/results?" + q, { replace: true });
    closeModal(MODAL_TYPE.refreshPrice);
  }, [closeModal, navigate, searchParams]);

  useEffect(() => {
    if (searchExpired && currentResults.length > 0) {
      customLog("search results timer is up");
      openModal(MODAL_TYPE.refreshPrice, { onRefresh: onRefreshResults });
    }
  }, [searchExpired, openModal, onRefreshResults, currentResults.length]);

  const onSignInHandler = (item) => {
    openModal(MODAL_TYPE.loginOptions, { onAfterLogin: () => onGoToBooking(item) });
  };

  function onContinueAsGuestHandler(item) {
    onGoToBooking(item);
  }

  const onOfferSignIn = (item) => {
    openModal(MODAL_TYPE.signInOffer, {
      onSignIn: () => onSignInHandler(item),
      onContinueAsGuest: () => onContinueAsGuestHandler(item),
    });
  };

  const onShowDetailsHandler = (item) => {
    openModal(MODAL_TYPE.flightDetails, {
      item: item,
      onContinue:
        isAuthenticated || !SIGNIN_ENABLED
          ? () => onGoToBooking(item)
          : () => onOfferSignIn(item),
    });
  };

  const onSelectResultHandler = (item) => {
    if (isMobile) {
      onShowDetailsHandler(item);
    } else if (!isAuthenticated && SIGNIN_ENABLED) {
      onOfferSignIn(item);
    } else {
      onGoToBooking(item);
    }
  };

  const resetRefreshTrigger = useCallback(() => setIsRefreshing(false), []);
  const showErrorComponent = (isWSError && hasFinishedDataTranfer) || requestData.error;

  return (
    <main id="search-results-page" className={isMobile ? "mobile" : "desktop"}>
      {isMobile ? (
        <MobileResultsHeader
          isLoadingBarEnabled={showLoadingBar}
          isRefreshing={isRefreshing}
          resetTrigger={resetRefreshTrigger}
        />
      ) : (
        <DesktopResultsHeader isLoadingBarEnabled={showLoadingBar} />
      )}
      <section className="results-content">
        {isMobile && (
          <ResultsSummary
            filteredResults={filteredResults.length}
            totalResults={currentResults.length}
            isLoading={showLoadingStates}
          />
        )}
        <PaddedView>
          {!isMobile && (
            <Fragment>
              <ResultsLeftPanel
                filteredResults={filteredResults.length}
                totalResults={currentResults.length}
                isLoading={showLoadingStates}
              />

              {showErrorComponent ? null : showLoadingStates ? (
                <LoadingResultsSorting />
              ) : (
                currentResults.length > 0 && (
                  <ResultsSorting
                    filteredResults={filteredResults.length}
                    totalResults={currentResults.length}
                  />
                )
              )}

              {showErrorComponent ? null : showLoadingStates ? (
                <LoadingResultsRightPanel />
              ) : (
                <ResultsRightPanel />
              )}
            </Fragment>
          )}

          {showErrorComponent && navigation.state !== "loading" ? (
            <NetworkErrorResultsNotFound />
          ) : showLoadingStates ? (
            <LoadingResultsList />
          ) : currentResults.length === 0 && hasFinishedDataTranfer ? (
            <SearchResultsNotFound />
          ) : (
            <ResultsMainPanel
              isNewRequest={isNewReq}
              results={currentResults}
              filteredResults={filteredResults}
              setFilteredResults={setFilteredResults}
              onSelectResult={onSelectResultHandler}
              onShowDetails={onShowDetailsHandler}
            />
          )}
        </PaddedView>
      </section>
    </main>
  );
}

export default SearchResults;
