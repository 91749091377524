import { json, redirect } from "react-router-dom";
import { ERROR_MESSAGES, ERROR_STATUS_MESSAGE } from "src/assets/lang/errorMessages";
import { BOOKING_STEP } from "src/constants";
import { FULL_CATALOGUE } from "src/constants/services";
import store from "src/store";
import { resetAfterBooking, updateTravelPackage } from "src/store/actions";
import { catalogueActions } from "src/store/catalogue";
import {
  getLastSavedSessionTravelPackage,
  getSelectedLocale,
  setSelectedSessionTravelPackage,
  setSessionId,
} from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import {
  processAdditionalServices,
  processBaggageServices,
  processSeatsServices,
} from "./utils/booking-catalogue-utils";
import { getBookingIDKey, processSessionId } from "./utils/booking-loader-utils";
import { RestoreUtil } from "./utils/booking-restore-utils";
import { auth } from "src/services/firebase";

export async function loader({ request, params }) {
  customLog(request);
  if (
    params.bookingStep &&
    !Object.values(BOOKING_STEP)
      .map((v) => v.path)
      .includes(params.bookingStep)
  ) {
    return redirect("/notfound");
  }
  store.dispatch(resetAfterBooking(true));
  customLog(Date.now());
  const searchParams = new URL(request.url).searchParams;
  let correlationID = searchParams.get("booking") ?? processSessionId();

  let lang = getSelectedLocale()?.split("_")[0] || "en";
  let travelPackage = { ...store.getState().booking.travelPackage };
  if (!travelPackage || Object.keys(travelPackage).length === 0) {
    travelPackage = getLastSavedSessionTravelPackage();
  }

  let existingBookingUpdates = [];
  if (correlationID) {
    const user = await new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      });
    });
    const { booking, updates, redirectData } = await RestoreUtil.getExistingBooking(
      correlationID,
      user
    );
    if (redirectData) return redirect(redirectData.path);
    travelPackage = booking;
    existingBookingUpdates = updates || [];
  }

  if (!travelPackage || Object.keys(travelPackage).length === 0) {
    throw json({
      data: null,
      errorMessage: ERROR_MESSAGES[lang].booking,
      statusText: [ERROR_STATUS_MESSAGE[lang].booking],
    });
  }

  if (!correlationID) {
    const bookingRes = await getBookingIDKey(travelPackage);
    correlationID = bookingRes.correlationId;
    travelPackage.booking_number = bookingRes.bookingNumber;
    setSessionId(correlationID);
  }

  travelPackage.originalPrice = travelPackage.price;

  setSelectedSessionTravelPackage(travelPackage);
  let searchQ = travelPackage.searchQ;

  const fullCatalogue = [...FULL_CATALOGUE];

  /** ANCILLARY SERVICES - SEATS */
  let seatsData = await processSeatsServices(travelPackage, fullCatalogue);

  /** ADDITIONAL SERVICES */
  let serviceData = await processAdditionalServices(fullCatalogue);

  /** ANCILLARY SERVICES - BAGS */
  processBaggageServices(travelPackage, fullCatalogue);

  store.dispatch(catalogueActions.loadServiceData(serviceData));
  store.dispatch(catalogueActions.loadAncillaryData(seatsData));
  store.dispatch(catalogueActions.loadCatalogue(fullCatalogue));
  store.dispatch(updateTravelPackage(travelPackage));

  let isBookingUpdate = existingBookingUpdates.length > 0;
  if (isBookingUpdate) {
    const restoredData = RestoreUtil.restoreBookingData(existingBookingUpdates);
    console.log(restoredData);

    RestoreUtil.updateStoreWithContact(restoredData.contact);
    RestoreUtil.updateStoreWithBilling(restoredData.billing);
    RestoreUtil.updateStoreWithPassengers(restoredData.passengers);
    RestoreUtil.updateStoreWithAdditionalServices(restoredData.order.additional);
    RestoreUtil.updateStoreWithAncillaryServices(restoredData.order.ancillary, travelPackage);
    RestoreUtil.updateStoreWithExplicitlyRefusedServices(existingBookingUpdates);
  }

  return { correlationID, searchQ, travelPackage, isBookingUpdate };
}
