import checkedBagDouble from "src/assets/img/bags/bags_0.png";
import checkedBag from "src/assets/img/bags/bags_1.png";
import carryOn from "src/assets/img/bags/bags_2.png";
import fastTrack from "src/assets/img/bags/bags_3.png";
import personalBag from "src/assets/img/bags/bags_4.png";
import { BAG_WEIGHT_CATEGORY } from "src/constants";
import { CATALOGUE_IDS } from "src/constants/services";
import {
  getBundledCabinBaggageDimensions,
  getBundledCabinBaggageUpgradeWeight,
  getBundledCabinBaggageUpgradeWeightDifference,
  getBundledCabinBaggageWeight,
  getBundledPersonalItemDimensions,
  getBundledPersonalItemWeight,
  getCabinBaggageBundlePrice,
  getPersonalItemDimensions,
  getPersonalItemWeight,
  getRegisteredBaggageDimensions,
  getRegisteredBaggagePrice,
  getRegisteredBaggageWeight,
  hasBundledCabinBaggageFastTrack,
  hasBundledCabinBaggageUpgradeFastTrack,
  hasCabinBundleUpgradeData,
} from "src/utils/baggage-utils";

export function createBagSelectionObject(travelPackage) {
  const data = {
    piOnlyData: {
      dim: getPersonalItemDimensions(travelPackage),
      weight: getPersonalItemWeight(travelPackage),
    },
    coBundleData: {
      piData: {
        dim: getBundledPersonalItemDimensions(travelPackage),
        weight: getBundledPersonalItemWeight(travelPackage),
      },
      coData: {
        dim: getBundledCabinBaggageDimensions(travelPackage),
        weight: getBundledCabinBaggageWeight(travelPackage),
      },
      fastTrack: hasBundledCabinBaggageFastTrack(travelPackage),
      hasUpgrade: hasCabinBundleUpgradeData(travelPackage),
      isFree: getCabinBaggageBundlePrice(travelPackage) === 0,
    },
    regDefaultData: {
      dim: getRegisteredBaggageDimensions(travelPackage, false, BAG_WEIGHT_CATEGORY.default),
      weight: getRegisteredBaggageWeight(travelPackage, false, BAG_WEIGHT_CATEGORY.default),
      isFree:
        getRegisteredBaggagePrice(travelPackage, false, BAG_WEIGHT_CATEGORY.default) === 0,
    },
    regLargeData: {
      dim: getRegisteredBaggageDimensions(travelPackage, false, BAG_WEIGHT_CATEGORY.larger),
      weight: getRegisteredBaggageWeight(travelPackage, false, BAG_WEIGHT_CATEGORY.larger),
      isFree:
        getRegisteredBaggagePrice(travelPackage, false, BAG_WEIGHT_CATEGORY.larger) === 0,
    },
    regDoubleData: {
      dim: getRegisteredBaggageDimensions(travelPackage, true),
      weight: getRegisteredBaggageWeight(travelPackage, true),
      isFree: getRegisteredBaggagePrice(travelPackage, true) === 0,
    },
  };

  if (data.regDoubleData.isFree) {
    data.regDefaultData.disabled = true;
    data.regLargeData.disabled = true;
  }
  else if (data.regLargeData.isFree) {
    data.regDefaultData.disabled = true;
  }

  if (data.coBundleData.hasUpgrade) {
    data.coBundleUpgradeData = {
      piData: {
        dim: data.coBundleData.piData.dim,
        weight: data.coBundleData.piData.weight,
      },
      coData: {
        dim: data.coBundleData.coData.dim,
        weight: getBundledCabinBaggageUpgradeWeight(travelPackage),
        upgradeWeight: getBundledCabinBaggageUpgradeWeightDifference(travelPackage),
      },
      fastTrack: hasBundledCabinBaggageUpgradeFastTrack(travelPackage),
    };
  }

  return data;
}

export const BAGGAGE_TITLE_MAP = {
  [CATALOGUE_IDS.pi_baggage]: "booking.form.baggage.cabin.personal",
  [CATALOGUE_IDS.cob_baggage]: "booking.form.baggage.cabin.bundle",
  [CATALOGUE_IDS.cob_baggage_upgrade]: "booking.form.baggage.cabin.bundle.upgrade",
  [CATALOGUE_IDS.r_baggage_def]: "booking.form.baggage.checked.single",
  [CATALOGUE_IDS.r_baggage_larger]: "booking.form.baggage.checked.single",
  [CATALOGUE_IDS.r_baggage_double]: "booking.form.baggage.checked.double",
};

export const BAG_IMG_MAP = {
  [CATALOGUE_IDS.pi_baggage]: [personalBag],
  [CATALOGUE_IDS.cob_baggage]: [personalBag, carryOn, fastTrack],
  [CATALOGUE_IDS.cob_baggage_upgrade]: [personalBag, carryOn, fastTrack],
  [CATALOGUE_IDS.r_baggage_def]: [checkedBag],
  [CATALOGUE_IDS.r_baggage_larger]: [checkedBag],
  [CATALOGUE_IDS.r_baggage_double]: [checkedBagDouble],
};

export const BAG_IMG_SIZE = {
  [CATALOGUE_IDS.pi_baggage]: ["3.4375rem"],
  [CATALOGUE_IDS.cob_baggage]: ["3.4375rem", "6.25rem", "4.6875rem"],
  [CATALOGUE_IDS.cob_baggage_upgrade]: ["3.4375rem", "6.25rem", "4.6875rem"],
  [CATALOGUE_IDS.r_baggage_def]: ["5.625rem"],
  [CATALOGUE_IDS.r_baggage_larger]: ["5.625rem"],
  [CATALOGUE_IDS.r_baggage_double]: ["9.0625rem"],
};

export const benefitsKeys = [
  "booking.form.baggage.protection.feature1",
  "booking.form.baggage.protection.feature2",
  "booking.form.baggage.protection.feature3",
];
