import classes from "./SignInPopup.module.css";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import Button from "src/components/UI/Button/Button";
import { Fragment } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import Modal from "src/components/shared/modal/Modal/Modal";
import DesktopModal from "src/components/shared/modal/Modal/DesktopModal";
import { ModalElementContext } from "src/context/modal-element-context";
import UserIcon from "src/components/UI/Icon/UserIcon";
import illustration from "src/assets/img/illustrations/illustr2.png"

function SignInPopup({ onClose, onSignIn, onContinueAsGuest, state }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const innerContent = (
    <Fragment>
      <div className={classes.image}>
        <img src={illustration} alt="travellers walking, famous european landmarks in the background"/>
      </div>
      <h1>{stringRes["res.modal.signin.title"]}</h1>
      <p>{`${stringRes["res.modal.signin.description"]}`}</p>
      <Button
        primary
        wide
        name={
          <span className={classes.button}>
            <UserIcon size={18} color={"white"} />
            <span>{stringRes["res.modal.signin.button"]}</span>
          </span>
        }
        onClick={onSignIn}
      />
      <div className={classes.hr}>
        <span>{stringRes["res.modal.signin.or"]}</span>
      </div>
      <button onClick={onContinueAsGuest} className={classes.guestOption}>
        {stringRes["res.modal.signin.guest.button"]}
      </button>
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color={"var(--ui-5)"} />
          </button>
          {innerContent}
        </div>
      </DesktopModal>
    );
  }
}

export default SignInPopup;
