import { Fragment, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import Card from "src/components/UI/Card/Card";
import useMousedownAway from "src/hooks/useMousedownAway";
import { customLog } from "src/utils/utils";
import classes from "./DesktopModal.module.css";

const portalElement = document.getElementById("overlays");

function DesktopModal({
  modalId,
  isClosing,
  children,
  onBackdropClick,
  classBody,
  modalClass,
}) {
  const modalRef = useRef();
  const animClass = isClosing ? classes.fadeout : classes.fadein;

  useEffect(() => {
    customLog(modalId + " run init logic");
    document.body.classList.add(classBody ?? classes.body);
    // const headerUserButton = document.getElementById("header-user-button");
    // headerUserButton.classList.add(classes.userBtn);
    return () => {
      customLog(modalId + " run cleanup logic");
      document.body.classList.remove(classBody ?? classes.body);
      // headerUserButton.classList.remove(classes.userBtn);
    };
  }, [modalId, classBody]);

  const overlay = (
    <div className={`${classes.backdrop} ${animClass}`}>
      <Card cornerLg className={classes.modal + " " + modalClass} ref={modalRef}>
        {children}
      </Card>
    </div>
  );

  useMousedownAway(modalRef, onBackdropClick);

  return <Fragment>{createPortal(overlay, portalElement)}</Fragment>;
}

export default DesktopModal;
