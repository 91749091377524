import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import classes from "./SearchForm.module.css";
import { TRIP_TYPE as TRIP } from "src/constants";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { searchDataActions, selectTripType } from "src/store/search";
import Modal from "src/components/shared/modal/Modal/Modal";
import TravelTypeItemGroup from "./elements/TravelTypeItemGroup/TravelTypeItemGroup";
import MobileMultiSearchForm from "./elements/MultiSearchForm/MobileMultiSearchForm";
import { customLog } from "src/utils/utils";
import { getSearchFormElementContentById } from "./SearchForm";
import { ModalElementContext } from "src/context/modal-element-context";
import { MobileSingleSearchForm } from "./elements/SingleSearchForm/MobileSingleSearchForm";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { tripModel } from "src/store/models";

import { secondDateIsLater } from "src/utils/date-utils";
import { useLocaleContext } from "src/context/locale-context";

function modalReducer(state, action) {
  if (action.type === "OPEN_MODAL") {
    return {
      targetTripId: action.tripId ? action.tripId : state.targetTripId,
      isElementClosing: false,
      currentElement: action.content,
    };
  } else if (action.type === "CLOSE_MODAL") {
    return { ...state, isElementClosing: true, currentElement: null };
  }
}

function sfStateReducer(state, action) {
  switch (action.type) {
    case "UPDATE_TRIP": {
      const updatedTrips = state.trips.map((trip) => {
        if (trip.id === action.id) {
          return { ...trip, ...action.payload };
        }
        return trip;
      });
      return { ...state, trips: updatedTrips };
    }
    case "UPDATE_PROPERTY":
      return { ...state, ...action.payload };
    case "ADD_TRIP":
      const lastTrip = state.trips[state.trips.length - 1];
      const newTrip = {
        ...cloneDeep(tripModel),
        id: action.id,
        srcLocation: lastTrip.destLocation,
        startDate: lastTrip.startDate,
      };
      const newTripsArr = state.trips.concat([newTrip]);
      return { ...state, trips: newTripsArr };
    case "REMOVE_TRIP":
      const tripIdx = state.trips.findIndex((t) => t.id === action.id);
      if (tripIdx > 0 && state.trips.length > 1) {
        const arrBefore = state.trips.slice(0, tripIdx);
        const arrAfter = state.trips.slice(tripIdx + 1);
        const newTripsArr = arrBefore.concat(arrAfter);
        return { ...state, trips: newTripsArr };
      } else {
        return state;
      }
    case "UPDATE_MULTI_START_DATES":
      const tripId = action.id;
      const idx = state.trips.findIndex((trip) => trip.id === tripId);
      const newTripArr = cloneDeep(state.trips);
      for (let i = 0; i < state.trips.length; i++) {
        const earlierDate = i < idx ? state.trips[i].startDate : state.trips[idx].startDate;
        const laterDate = i > idx ? state.trips[i].startDate : state.trips[idx].startDate;
        if (secondDateIsLater(laterDate, earlierDate)) {
          newTripArr[i].startDate = "";
        }
      }
      return { ...state, trips: newTripArr };
    default:
      return state;
  }
}

export function MobileSearchForm({ isResultsPage, onSearchStart, preValidate }) {
  const dispatch = useDispatch();
  const { stringRes, currentLocale } = useLocaleContext();
  const lang = currentLocale.split("_")[0].toUpperCase();
  const { onClose } = useContext(ModalElementContext);
  const searchData = useSelector((state) => state.search, shallowEqual);
  const tripType = useSelector(selectTripType);

  const [isButtonEnabled, setButtonEnabled] = useState(!isResultsPage);

  const [checkAccommodation, setCheckAccommodation] = useState(false);
  const [modalState, dispatchModal] = useReducer(modalReducer, {
    targetTripId: searchData.trips[0].id,
    currentElement: null,
    isElementClosing: false,
  });

  const initResultsSFState = (sfData) => {
    const clonedSfData = cloneDeep(sfData);
    delete clonedSfData.userInput;
    return clonedSfData;
  };
  const [resultsSFState, dispatchSFState] = useReducer(
    sfStateReducer,
    initResultsSFState(searchData)
  );

  const prevSFData = useRef(cloneDeep(resultsSFState));

  useEffect(() => {
    if (!isResultsPage) {
      const searchFormHeader = document.getElementById("search-form-section-mob");
      searchFormHeader.style.backgroundImage = "none";
    }
  }, [isResultsPage]);

  useEffect(() => {
    customLog("apparently, SF data changed - mobile");
    const clonedSfData = cloneDeep(resultsSFState);
    if (isResultsPage && prevSFData.current) {
      if (!isEqual(clonedSfData, prevSFData.current)) {
        setButtonEnabled(true);
      } else {
        setButtonEnabled(false);
      }
    }
  }, [resultsSFState, isResultsPage]);

  const onSelectTripTypeHandler = (type) => {
    if (isResultsPage) {
      dispatchSFState({ type: "UPDATE_PROPERTY", payload: { tripType: type } });
      if (type !== TRIP.roundtrip) {
        dispatchSFState({
          type: "UPDATE_TRIP",
          id: resultsSFState.trips[0].id,
          payload: { endDate: "" },
        });
      }
      if (type !== TRIP.multicity) {
        for (let i = 1; i < resultsSFState.trips.length; i++) {
          const tripId = resultsSFState.trips[i].id;
          dispatch(searchDataActions.removeUserInputTrip({ tripId: tripId }));
          dispatchSFState({ type: "REMOVE_TRIP", id: tripId });
        }
      }
    } else {
      dispatch(searchDataActions.updateTripType(type));
      if (type !== TRIP.roundtrip) {
        dispatch(
          searchDataActions.updateEndDate({
            tripId: searchData.trips[0].id,
            date: "",
          })
        );
      }
    }
  };

  const onOpenSelectionElement = (inputId, tripId) => {
    customLog("this is " + inputId);
    const content = getSearchFormElementContentById(inputId);
    dispatchModal({ type: "OPEN_MODAL", tripId: tripId, content });
  };

  const onCloseSelectionScreen = useCallback(() => {
    customLog("closing a modal");
    dispatchModal({ type: "CLOSE_MODAL" });
  }, []);

  const onCheckboxChangeHandler = (id, isChecked) => {
    if (id === "include-accommodation") {
      setCheckAccommodation(isChecked);
    }
  };

  const onClickSearchHandler = () => {
    let tripTypeValue = isResultsPage ? resultsSFState.tripType : tripType;
    let sfData = isResultsPage ? resultsSFState : searchData;
    const res = onSearchStart(
      tripTypeValue,
      sfData,
      isResultsPage ? false : checkAccommodation,
      onOpenSelectionElement
    );
    if (res && isResultsPage) {
      dispatch(searchDataActions.copyState({ data: resultsSFState }));
      dispatch(searchDataActions.fillUserInputPassengerOptions());
      dispatch(searchDataActions.fillUserInputTrips(lang));
      onClose();
    }
  };

  const sfProps = {
    onSearchFormClick: onOpenSelectionElement,
    onStartSearch: onClickSearchHandler,
    isResultsPage: isResultsPage,
    isButtonEnabled: isResultsPage
      ? preValidate(resultsSFState.tripType, resultsSFState) && isButtonEnabled
      : true,
    searchData: isResultsPage ? resultsSFState : searchData,
    dispatchSFState: dispatchSFState,
  };

  const isMultiCity =
    (isResultsPage && resultsSFState.tripType === TRIP.multicity) ||
    (!isResultsPage && tripType === TRIP.multicity);

  return (
    <Fragment>
      <h1 className={isResultsPage ? classes.results : ""}>
        {isResultsPage ? stringRes["sf.mobile.button.modify"] : stringRes["sf.mobile.title"]}
      </h1>
      <TravelTypeItemGroup
        onSelected={onSelectTripTypeHandler}
        tripType={isResultsPage ? resultsSFState.tripType : tripType}
      />
      {isMultiCity ? (
        <MobileMultiSearchForm {...sfProps} />
      ) : (
        <MobileSingleSearchForm
          {...sfProps}
          onCheckboxChange={onCheckboxChangeHandler}
          checkValue={checkAccommodation}
        />
      )}
      <ModalElementContext.Provider
        value={{
          onClose: onCloseSelectionScreen,
          targetTripId: modalState.targetTripId,
          isResultsModal: isResultsPage,
          dispatchLocalState: dispatchSFState,
          resultsSFData: resultsSFState,
        }}>
        {modalState.currentElement && (
          <Modal isClosing={modalState.isElementClosing} fullscreen>
            {modalState.currentElement}
          </Modal>
        )}
      </ModalElementContext.Provider>
    </Fragment>
  );
}
