import { Fragment } from "react";

export function FlightConnectionLine({ stopNum, className, width }) {
  const stops = stopNum > 1 ? [null, null] : stopNum > 0 ? [null] : [];

  const flightNode = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <circle cx="3.5" cy="3.5" r="2.5" fill="#BAC7D5" stroke="#BAC7D5" strokeWidth="2" />
    </svg>
  );

  const line = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 52}
      height="3"
      viewBox="0 0 52 3"
      fill="none"
    >
      <path
        d="M0.995117 1.5H26.0001H51.0051"
        stroke="#BAC7D5"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );

  const stopNode = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <circle cx="3.5" cy="3.5" r="3" fill="white" stroke="var(--ui-5)" />
    </svg>
  );

  return (
    <span className={className}>
      {flightNode}
      {line}
      {flightNode}
      {stopNum > 0 && (
        <span>
          {stops.map((s, i) => (
            <Fragment key={i}>{stopNode}</Fragment>
          ))}
        </span>
      )}
    </span>
  );
}
