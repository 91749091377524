import { axiosInstanceWCompany } from "./axios-config";

export const localeApi = {
  getStringResources: (locale) => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/resources/category/resources/${locale}`,
    });
  },
  getAdditionalStringResources: (locale) => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/resources/category/additional_services/${locale}`,
    });
  },
};

export const companyProfileApi = {
  getCompanyProfileData: (companyId) => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/tenant/name/${companyId}`,
    });
  },
};

export const airportsApi = {
  getAirports: (query, locale) => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/airport/search?locale=${locale}&${query}`,
    });
  },
  getPopularDestinationAirports: (iata) => {
    let url = `/destination/source`;
    url += iata ? `/${iata}` : "";
    return axiosInstanceWCompany.request({
      method: "GET",
      url: url,
    });
  },
  getPopularArrivalAirports: (iata) => {
    let url = `/destination/popular`;
    url += iata ? `/${iata}` : "";
    return axiosInstanceWCompany.request({
      method: "GET",
      url: url,
    });
  },
};

export const resultsApi = {
  getSearchResultsKey: (query) => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/search${query}`,
    });
  },
  getSpecificFlight: (query, mergeKey, dataProvider) => {
    console.log(`/search?${query}&merge_key=${mergeKey}&data_provider=${dataProvider}`);
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/search?${query}&merge_key=${mergeKey}&data_provider=${dataProvider}`,
    });
  },
};

export const additionalServicesApi = {
  getServices: () => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/services/all`,
    });
  },
};

export const ancillaryServicesApi = {
  getServices: (carrierCode) => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/luggage/code/${carrierCode}`,
    });
  },
};

export const bookingOrderApi = {
  startBooking: (travelPackage) => {
    return axiosInstanceWCompany.request({
      method: "POST",
      data: travelPackage,
      url: `/booking/create`,
    });
  },
  getOrderById: (orderId) => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: `/booking/retrieve/${orderId}`,
    });
  },
};

export const paymentApi = {
  getPaymentMethods: () => {
    return axiosInstanceWCompany.request({
      method: "GET",
      url: "/payment/methods",
    });
  },
  processPaymentOrder: (payload) => {
    return axiosInstanceWCompany.request({
      method: "POST",
      data: { payload },
      headers: {
        "Content-Type": "application/json",
      },
      url: "/payment/order",
    });
  },
  checkPaymentOrderToken: (token) => {
    return axiosInstanceWCompany.request({
      method: "POST",
      url: "/payment/validate",
      data: { token },
    });
  },
};

export const staticApi = {
  getStaticFiles: async (company, language, folderName) => {
    return axiosInstanceWCompany
      .request({
        method: "GET",
        url: `/static/links/${folderName}/${company}/${language}`,
      })
      .then(async (res) => {
        if (!Array.isArray(res.data) || res.data.length === 0) {
          throw new Error(
            "Invalid data structure: expected an array with at least one element."
          );
        }

        const data = {};
        const promises = [];
        res.data.forEach(async (targetLink) => {
          if (!targetLink) throw new Error("No target link found.");

          const ext = targetLink.split(".").pop();
          if (ext === "css" || ext === "html") {
            const promise = axiosInstanceWCompany
              .request({
                method: "GET",
                url: targetLink,
                headers: { "Content-Type": `text/${ext}` },
                responseType: "text",
              })
              .then((res) => {
                data[ext] = res.data;
              })
              .catch((err) => {
                console.log(err);
                throw err;
              });
            promises.push(promise);
          }
        });
        await Promise.all(promises);
        return data;
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          return { html: "", css: "" };
        } else {
          throw err;
        }
      });
  },
};
