import classes from "./AddButtonOval.module.css";
import { motion } from "framer-motion";
import { useState } from "react";
import { useLocaleContext } from "src/context/locale-context";

function AddButtonOval({ onClick, isAdded }) {
  const { stringRes } = useLocaleContext();
  const text =
    stringRes[isAdded ? "booking.mob.service.added.button" : "booking.mob.service.add.button"];
  const [colorHex] = useState(() => {
    const cssVariable = getComputedStyle(document.documentElement).getPropertyValue(
      "--primary-1"
    );
    return cssVariable;
  });

  const variants = {
    added: {
      backgroundColor: "var(--primary-1)",
      minWidth: "112px",
      width: text.length * 16 + "px",
    },
    removed: {
      backgroundColor: "var(--primary-tint)",
      minWidth: "95px",
      width: text.length * 16 + "px",
    },
  };
  return (
    <motion.button
      animate={isAdded ? "added" : "removed"}
      transition={{ duration: 0.15, ease: "easeIn", type: "just" }}
      whileTap={{ scale: 0.9 }}
      variants={variants}
      initial={false}
      onClick={onClick}
      className={`${classes.addButton} ${isAdded ? classes.active : classes.inactive}`}>
      <span className={classes.icon}>
        {isAdded ? (
          <svg
            width="12"
            height="12"
            viewBox="2 -2 6 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.11088 4.70545C0.865168 4.4479 0.457189 4.43829 0.199631 4.684C-0.0579267 4.92971 -0.067531 5.33769 0.178179 5.59525L2.73665 8.27708C3.00009 8.55323 3.44446 8.54125 3.69264 8.25131L9.8451 1.06366C10.0766 0.793241 10.045 0.386372 9.77458 0.154896C9.50416 -0.07658 9.09729 -0.0450073 8.86582 0.225416L3.46489 6.53507C3.31087 6.71501 3.03509 6.72245 2.87159 6.55107L1.11088 4.70545Z"
              fill="white"
              stroke="white"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.00022 3.40662C8.33159 3.40662 8.60022 3.67525 8.60022 4.00662L8.60021 6.99862C8.6002 7.21953 8.77929 7.39862 9.0002 7.39862L11.9922 7.39863C12.2981 7.39863 12.5505 7.62753 12.5876 7.92337L12.5922 7.99863C12.5922 8.33001 12.3236 8.59863 11.9922 8.59863L9.00021 8.59862C8.77929 8.59862 8.6002 8.7777 8.60021 8.99862L8.60022 11.9907C8.60022 12.2965 8.37133 12.549 8.07549 12.586L8.00022 12.5907C7.66885 12.5907 7.40022 12.322 7.40022 11.9907L7.40021 8.99861C7.4002 8.7777 7.22112 8.59862 7.0002 8.59862L4.0082 8.59863C3.70232 8.59863 3.4499 8.36974 3.41288 8.0739L3.4082 7.99863C3.4082 7.66726 3.67683 7.39863 4.0082 7.39863L7.00021 7.39862C7.22112 7.39862 7.4002 7.21953 7.40021 6.99862L7.40022 4.00662C7.40022 3.70074 7.62911 3.44832 7.92496 3.41129L8.00022 3.40662Z"
              fill="var(--primary-1)"
              stroke="var(--primary-1)"
            />
          </svg>
        )}
      </span>
      <span className={classes.text}>{text}</span>
    </motion.button>
  );
}

export default AddButtonOval;
