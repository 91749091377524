import { CABIN_CLASS, PASSENGER, TRIP_TYPE } from "src/constants";
import { tripModel } from "src/store/models";
import { isValidDateString } from "./date-utils";

export const customLog = console.log.bind(window.console);
// {
//   // if (process.env.NODE_ENV !== "production") {
//   const args = [...arguments];
//   for (let i = 0; i < args.length; i++) {
//     console.log(args[i]);
//   }
//   // }
// }

export function calculateTotalPassengers(passengers) {
  return Object.values(passengers).reduce((a, b) => a + b);
}

export function validateTripType(type) {
  return Object.values(TRIP_TYPE).includes(type);
}

export function validateCabinClass(cabin) {
  return cabin ? Object.values(CABIN_CLASS).includes(cabin) : true;
}

export function validatePassengers(passengers) {
  if (validateKeys(Object.keys(PASSENGER), passengers)) {
    for (const value in Object.values(passengers)) {
      if (!Number.isInteger(+value)) {
        return false;
      }
    }
    return validatePassengerCount(passengers);
  }
  return false;
}

function validateLocation(location) {
  return location && validateKeys(["city", "iata", "isCity", "cityIata"], location, false);
}

export function validateDateString(dateString) {
  return dateString && isValidDateString(dateString);
}

export function validateKeys(validKeys, obj, strict = true) {
  if (strict) {
    let keyMatch = 0;
    for (const key in Object.keys(obj ?? {})) {
      if (!(key in validKeys)) {
        return false;
      } else {
        keyMatch++;
      }
    }
    return keyMatch === validKeys.length;
  } else {
    for (const key in validKeys) {
      if (!(key in Object.keys(obj ?? {}))) {
        return false;
      }
    }
    return true;
  }
}

export function validatePassengerCount(passengers) {
  const MAX_PASSENGERS = 9;
  if (
    calculateTotalPassengers(passengers) > MAX_PASSENGERS ||
    passengers[PASSENGER.adult] < 1 ||
    passengers[PASSENGER.adult] < passengers[PASSENGER.infant]
  ) {
    return false;
  }
  return true;
}

export function validateTripFields(trip) {
  if (
    !validateKeys(Object.keys(tripModel), trip) ||
    !validateLocation(trip.srcLocation) ||
    !validateLocation(trip.destLocation) ||
    !validateDateString(trip.startDate) ||
    (trip.endDate && !validateDateString(trip.endDate))
  ) {
    return false;
  }
  return true;
}

export function validateTrips(trips) {
  const MAX_TRIP_NUM = 6;
  if (Array.isArray(trips) && trips.length > 0 && trips.length <= MAX_TRIP_NUM) {
    for (let i = 0; i < trips.length; i++) {
      if (!trips[i] || !validateTripFields(trips[i])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function compareToValidatedValue(cityName, latestIata, validatedValue, lang) {
  const validatedString = validatedValue.names?.[lang] + validatedValue.iata;
  const validatedStringEng = validatedValue.city + validatedValue.iata;
  const userInputValue = cityName + latestIata;
  return userInputValue === validatedString || userInputValue === validatedStringEng;
}

export function getDisplayLocationValueMobile(location, lang) {
  if (location.iata && location.city) {
    return [location.names[lang] || location.city, location.iata].join(", ");
  } else {
    return "";
  }
}

export function toTitleCase(str) {
  return str?.toLowerCase().replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function parseIntFromString(strVal, fallbackVal) {
  return isNaN(parseInt(strVal)) ? fallbackVal : parseInt(strVal);
}

export function getDisplayPriceValue(price) {
  if (price !== undefined || price !== null) {
    return price % 1 > 0 ? price.toFixed(2) : price;
  }
}

export function sortDictionaryAtoZ(dictionary) {
  const entries = Object.entries(dictionary);
  // Sort the array of entries based on the values in alphabetical order
  entries.sort((a, b) => a[1].localeCompare(b[1]));
  const sortedDictionary = Object.fromEntries(entries);
  return sortedDictionary;
}

export function getDefaultCountryCode() {
  const defaultLocale = window.SERVER_DATA.REACT_APP_DEFAULT_DOMAIN_LOCALE;
  const defCC = defaultLocale.split("-")[1];
  return defCC ? defCC : "LV";
}

export const ANIM_PROPS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export function hexToHSL(hex) {
  hex = hex.replace("#", "");
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h, s;
  let l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    // eslint-disable-next-line default-case
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h *= 60;
  }

  return [Math.round(h), Math.round(s * 100), Math.round(l * 100)];
}

export function doMoneyMultiplication(amount, factor) {
  const wholes = Math.floor(amount);
  const cents = Math.round((amount % 1) * 100);
  return wholes * factor + (cents * factor) / 100;
}

export function doPriceAddition(price1, price2) {
  const centsSum = Math.round((price1 % 1) * 100) + Math.round((price2 % 1) * 100);
  const centsSumWholes = Math.floor(centsSum / 100);
  return (
    Math.floor(price1) +
    Math.floor(price2) +
    centsSumWholes +
    (centsSum - centsSumWholes * 100) / 100
  );
}

export function isMobileUserAgent() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}