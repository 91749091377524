import { Fragment, useEffect, useRef, useState } from "react";
import classes from "./ResultsSorting.module.css";
import { SORT } from "src/constants";
import { SORT_TYPE_MAP } from "src/constants/string-res-map";
import { customLog, toTitleCase } from "src/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { filterSortActions } from "src/store/filter-sort";
import { ExpandArrow } from "src/components/UI/Arrow/Arrow";
import useClickAway from "src/hooks/useClickAway";
import { Checkmark } from "src/components/UI/Icon/Checkmark";
import { useLocaleContext } from "src/context/locale-context";
import { useSearchParams } from "react-router-dom";

function SortingItemWithOptions({ onClick, onExpand, firstOption, isActive, isSorting }) {
  const { stringRes } = useLocaleContext();
  const sortValues = useSelector((state) => state.filterSort.sort);
  const currentSort = sortValues.type;

  const onClickSort = () => {
    if (currentSort !== firstOption) {
      onClick(firstOption);
    }
  };

  return (
    <Fragment>
      <button
        style={{ minWidth: "13.375rem" }}
        type="button"
        className={`${classes.sortItem} ${classes.withOpts} ${isActive ? classes.active : ""}`}
        onClick={onClickSort}>
        <div className={classes.expandable}>
          <span className={classes.label}>{stringRes["res.sort.other.label"]}</span>
          <span className={classes.info}>{stringRes[SORT_TYPE_MAP[firstOption]]}</span>
        </div>
        <div
          className={classes.arrow}
          onClick={(e) => {
            e.stopPropagation();
            onExpand();
          }}>
          <ExpandArrow />
        </div>
      </button>
    </Fragment>
  );
}

function SortingItem({ onClick, sortType, isSorting }) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const sortValues = useSelector((state) => state.filterSort.sort);
  const currentSort = sortValues.type;
  const vals = sortValues[sortType];

  const onClickSort = () => {
    if (currentSort !== sortType) {
      onClick(sortType);
    }
  };

  return (
    <button
      name="sort-type"
      type="button"
      className={`${classes.sortItem} ${currentSort === sortType ? classes.active : ""}`}
      onClick={onClickSort}>
      <span className={classes.label}>{stringRes[SORT_TYPE_MAP[sortType]]}</span>
      <span className={classes.info}>
        {`${vals.price}${currencySymbol} • ${Math.trunc(vals.duration / 60)}h ${
          vals.duration % 60
        }m`}
      </span>
    </button>
  );
}

export function ResultsSorting({ filteredResults, totalResults }) {
  const dispatch = useDispatch();
  const { stringRes, currencySymbol } = useLocaleContext();
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const sortValues = useSelector((state) => state.filterSort.sort);
  const isSorting = useSelector((state) => state.filterSort.isSorting);

  const currentSort = sortValues.type;
  const optionsRef = useRef(null);

  const sortOpts = [SORT.earlyDeparture, SORT.earlyArrival];
  const isActive = sortOpts.includes(currentSort);

  const onSort = (type) => {
    customLog("sorting item " + type);
    dispatch(filterSortActions.setSortType(type));
  };

  useEffect(() => {
    if (isSorting) {
      setIsOpen(false);
    }
  }, [isSorting]);

  const onOpenSelection = () => {
    setIsOpen(true);
  };

  useClickAway(optionsRef, () => setIsOpen(false));

  const sortDepCityName = searchParams[0].get("src")
    ? toTitleCase(searchParams[0].get("src"))
    : "N/A";
  const sortArrCityName = searchParams[0].get("dest")
    ? toTitleCase(searchParams[0].get("dest"))
    : "N/A";

  const sortProps = {
    onClick: onSort,
    isSorting: isSorting,
  };

  return (
    <Fragment>
      {((totalResults > 0 && filteredResults > 0) || (isSorting && filteredResults === 0)) && (
        <section className={`sorting ${classes.loaded} ${isSorting ? classes.isSorting : ""}`}>
          <div className={`sorting-box ${classes.box}`}>
            <SortingItem sortType={SORT.best} {...sortProps} />
            <SortingItem sortType={SORT.cheapest} {...sortProps} />
            <SortingItem sortType={SORT.fastest} {...sortProps} />
            <SortingItemWithOptions
              onExpand={onOpenSelection}
              {...sortProps}
              firstOption={isActive ? currentSort : sortOpts[0]}
              isActive={isActive}
            />
          </div>
          {isOpen && (
            <ul ref={optionsRef} className={`theme-sort-context-menu ${classes.options}`}>
              {sortOpts.map((opt, i) => {
                const vals = sortValues[opt];
                return (
                  <li
                    key={opt}
                    className={currentSort === opt ? classes.selected : ""}
                    onClick={() => {
                      if (currentSort !== opt) {
                        onSort(opt);
                      }
                      setIsOpen(false);
                    }}>
                    <span className={classes.label}>
                      <Checkmark />
                      {`${stringRes[SORT_TYPE_MAP[opt]]} (${
                        i === 0 ? sortDepCityName : sortArrCityName
                      })`}
                    </span>
                    <span className={classes.info}>{`${
                      vals.price
                    }${currencySymbol} • ${Math.trunc(vals.duration / 60)}h ${
                      vals.duration % 60
                    }m`}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </section>
      )}
    </Fragment>
  );
}

function LoadingSortingItem() {
  return (
    <div className={classes.sortItem}>
      <div></div>
      <div></div>
    </div>
  );
}

export function LoadingResultsSorting() {
  const loadingItems = [];
  for (let i = 0; i < 4; i++) {
    loadingItems.push(i);
  }
  return (
    <section className={`sorting ${classes.loading}`}>
      {loadingItems.map((i) => (
        <LoadingSortingItem key={i} />
      ))}
    </section>
  );
}
