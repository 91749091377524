import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet, ScrollRestoration, useLoaderData } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Card from "src/components/UI/Card/Card";
import { MAX_SCREEN_WIDTH_MOBILE } from "src/constants";
import DeviceContext from "src/context/device-context";
import { GlobalLoadingProvider } from "src/context/loading-context";
import { LocaleContextManager } from "src/context/locale-context";
import { ModalContextManager } from "src/context/modal-stack-context";
import { PriceExpiryManager } from "src/context/refresh-price-context";
import { TenantContextManager } from "src/context/tenant-context";
import { isMobileUserAgent } from "src/utils/utils";

function RootLayout() {
  const [isMobileUA] = useState(isMobileUserAgent());
  const isMobile = useMediaQuery({ maxWidth: MAX_SCREEN_WIDTH_MOBILE });
  const { isLocalStorageAvailable, isSessionStorageAvailable } = useLoaderData();

  // const [theme, setTheme] = useState(window.SERVER_DATA.REACT_APP_TENANT_NAME);

  // useEffect(() => {
  //   const oldStylesheet = document.getElementById("theme-dynamic");
  //   if (oldStylesheet) {
  //     oldStylesheet.remove();
  //   }

  //   if (theme) {
  //     const link = document.createElement("link");
  //     link.rel = "stylesheet";
  //     link.href = `https://avion-dev.s3.pl-waw.scw.cloud/static/${theme}/en/assets/styles.css`;
  //     link.id = "theme-dynamic";
  //     link.type = "text/css";
  //     document.head.appendChild(link);
  //     window.SERVER_DATA.REACT_APP_TENANT_NAME = theme;
  //   }
  // }, [theme]);

  if (!isLocalStorageAvailable || !isSessionStorageAvailable) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: "Arial, sans-serif",
        }}>
        <Card cornerSm>
          <p>
            Your browser does not support{" "}
            <strong>{!isLocalStorageAvailable ? "localStorage" : ""}</strong>{" "}
            {!isLocalStorageAvailable && !isSessionStorageAvailable ? "and" : ""}{" "}
            <strong>{!isSessionStorageAvailable ? "sessionStorage" : ""}</strong>. Please
            enable them to use the website.
          </p>
        </Card>
      </div>
    );
  }

  const DOMAIN_TENANT_ID = window.SERVER_DATA.REACT_APP_TENANT_NAME;
  return (
    <TenantContextManager tenantID={DOMAIN_TENANT_ID}>
      <LocaleContextManager companyId={DOMAIN_TENANT_ID}>
        <PriceExpiryManager>
          <DeviceContext.Provider value={{ isMobile: isMobileUA || isMobile }}>
            <GlobalLoadingProvider>
              <ModalContextManager>
                <div id="root-container">
                  <Outlet />
                </div>
                {/* <button
                  onClick={() => setTheme("celotleti")}
                  style={{ position: "fixed", bottom: 10, left: 10 }}>
                  Celotleti Theme
                </button>
                <button
                  onClick={() => setTheme("default")}
                  style={{ position: "fixed", bottom: 40, left: 10 }}>
                  Avion.lv Theme
                </button>
                <div style={{ position: "fixed", bottom: 70, left: 10 }}>
                  current: {DOMAIN_TENANT_ID}
                </div> */}
                <ToastContainer
                  position="bottom-right"
                  hideProgressBar
                  draggable={false}
                  theme="colored"
                />
                <ScrollRestoration />
              </ModalContextManager>
            </GlobalLoadingProvider>
          </DeviceContext.Provider>
        </PriceExpiryManager>
      </LocaleContextManager>
    </TenantContextManager>
  );
}

export default RootLayout;
