import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { companyProfileApi } from "src/services/api";
import { customLog, hexToHSL } from "src/utils/utils";

const TenantContext = createContext({
  contactData: {},
  companyName: "",
  currency: "",
  email: "",
  phone: "",
  address: "",
  website: "",
  privacyNotes: "",
  dateFormat: "",
  shortDateFormat: "",
  footer: true,
  phonePrefixes: [],
});

export const TenantContextManager = ({ tenantID, children }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    projectCurrency: "EUR",
    colorSchema: {
      primaryColor: "#1665e3",
      secondaryColor: "#001b38",
      accentColor: "#04b1a7",
    },
    contactInformation: {},
    dateFormat: "DD/MM/YYYY",
    shortDateFormat: "DD/MM",
    footer: true,
    phonePrefixes: ["371", "370", "372"],
  });

  useEffect(() => {
    if (!tenantID) {
      throw Error("Missing tenant ID");
    }
    companyProfileApi
      .getCompanyProfileData(tenantID)
      .then((data) => {
        const tenantData = data.data;
        customLog(tenantData);
        setState((current) => {
          return { ...current, ...tenantData };
        });
      })
      .catch(customLog);
  }, [dispatch, tenantID]);

  return (
    <TenantContext.Provider
      value={{
        contactData: state.contactInformation,
        currency: state.projectCurrency,
        email: state.contactInformation.email,
        phone: state.contactInformation.phone,
        address: state.contactInformation.address,
        website: state.contactInformation.website,
        companyName: state.contactInformation.companyName,
        privacyNotes: state.privacyNotes,
        dateFormat: state.dateFormat,
        shortDateFormat: state.shortDateFormat,
        footer: state.footer,
        phonePrefixes: state.phonePrefixes,
      }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContext;

export const useTenantContext = () => useContext(TenantContext);
