import { LoadingIndicatorBar } from "src/components/UI/LoadingIndicatorBar/LoadingIndicatorBar";
import SearchForm from "../../search/SearchForm/SearchForm";
import classes from "./DesktopResultsHeader.module.css";

function DesktopResultsHeader({ isLoadingBarEnabled }) {
  return (
    <section id="search-form-section-results" className={`results-header ${classes.header}`}>
      <SearchForm />
      <LoadingIndicatorBar isLoading={isLoadingBarEnabled} height={4} />
    </section>
  );
}

export default DesktopResultsHeader;
