import { Fragment } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "src/components/shared/footer/Footer/Footer";
import TopNavigation from "src/components/shared/navigation/TopNavigation/TopNavigation";
import { useTenantContext } from "src/context/tenant-context";

function WithNavigationLayout() {
  const { pathname } = useLocation();
  const { footer } = useTenantContext();
  const hideFooter = pathname === "/search/results" || !footer;

  return (
    <Fragment>
      <TopNavigation />
      <Outlet />
      {!hideFooter && <Footer />}
    </Fragment>
  );
}

export default WithNavigationLayout;
