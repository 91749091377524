import { customLog } from "src/utils/utils";
import classes from "./Checkbox.module.css";
import { useDeviceContext } from "src/context/device-context";
import { InnerCheckmark } from "./InnerCheckmark";

export function Checkbox({
  id,
  isChecked,
  label,
  onChange,
  name,
  className,
  isSameAsMobile = false,
  isClickTextEnabled = true,
  checkMarkColor = "var(--primary-1)",
  checkMarkThickness = 2,
}) {
  const { isMobile } = useDeviceContext();

  const onChangeHandler = (event) => {
    const checkValue = event.target.checked;
    customLog(checkValue);
    onChange(id, checkValue);
  };

  const onSelectElement = (e) => {
    customLog("checkbox text clicked");
    const inpEl = document.getElementById(id);
    onChange(id, !inpEl.checked);
  };

  const onKeyUpHandler = (e) => {
    customLog("checkbox text key action");
    if (e.key === "Enter") {
      const inpEl = document.getElementById(id);
      onChange(id, !inpEl.checked);
    }
  };

  return (
    <div
      onClick={isClickTextEnabled ? onSelectElement : () => {}}
      className={`${classes.container} ${classes.checkbox} ${
        !isMobile && !isSameAsMobile ? classes.desktop : ""
      } ${isClickTextEnabled ? "" : classes.noTextClick}`}>
      <label htmlFor={id} hidden />
      <span className={isChecked ? classes.checked : ""}>
        {isChecked && <InnerCheckmark thickness={checkMarkThickness} color={checkMarkColor} />}
        <input
          id={id}
          type="checkbox"
          name={name ? name : ""}
          checked={isChecked}
          className={`${classes.container} ${className ?? ""}`}
          onChange={onChangeHandler}
          onClick={(e) => e.stopPropagation()}
          onKeyUp={onKeyUpHandler}
        />
      </span>
      <span style={{ marginTop: "2px" }}>{label}</span>
    </div>
  );
}
