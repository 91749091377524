import { XIconUnavailable } from "src/components/UI/Icon/XIcon";
import classes from "../BaggageSelection.module.css";
import { Checkmark } from "src/components/UI/Icon/Checkmark";

export function BaggageOptionAvailability({ isAvailable, text }) {
  return (
    <div className={classes.availability}>
      {isAvailable ? <Checkmark color="#04b1a7" /> : <XIconUnavailable />}
      <span className={classes.description}>{text}</span>
    </div>
  );
}
