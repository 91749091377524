export const STATIC_ELEMENT = {
  footer: "footer",
  terms: "terms",
  privacy: "privacy",
  refunds: "refunds",
  guarantee: "guarantee",
  sms: "sms_service",
  airhelp: "airhelp",
  checkin: "checkin",
  common_faq: "common_faq",
  about_us: "about_us",
};
