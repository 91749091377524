import classes from "./Registration.module.css";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { useDeviceContext } from "src/context/device-context";
import { Fragment, useState } from "react";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "../Modal/Modal";
import DesktopModal from "../Modal/DesktopModal";
import { useLocaleContext } from "src/context/locale-context";
import {
  FormInputLabel,
  FormInputPassword,
  FormInputText,
} from "src/components/shared/input/FormInput/FormInput";
import { EMAIL_PATTERN } from "src/constants/validation";
import Button from "src/components/UI/Button/Button";
import { useAuth } from "src/context/auth-context";
import { WarningIcon } from "src/components/UI/Icon/WarningIcon";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import zxcvbn from "zxcvbn";
import { motion } from "framer-motion";
import { Interweave } from "interweave";
import { transformNewTabLinks } from "src/utils/string-utils";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import illustration from "src/assets/img/illustrations/illustr3.png";

function encloseLinksInHTMLTags(inputString, links) {
  let transformedString = inputString;
  links.forEach(
    (l) =>
      (transformedString = transformedString.replace(
        l.strVar,
        `<a href='${l.link}'><b>${l.name}</b></a>`
      ))
  );
  return transformedString;
}

function CredentialInputComponent({
  onContinue,
  email,
  password,
  onEmailChange,
  onPasswordChange,
  state,
  onFocus,
  onBlur,
}) {
  const { stringRes } = useLocaleContext();

  return (
    <Fragment>
      <div className={classes.cred}>
        <FormInputLabel
          hasWarning={state.email.doWarn}
          infoText={state.email.warnings}
          text={stringRes["register.modal.field.email"]}>
          <FormInputText
            placeholder={stringRes["login.modal.email.placeholder"]}
            onChange={(e) => onEmailChange(e.target.value)}
            value={email}
            isValid={!state.email.hasBeenTouched || state.email.isValid}
            isActive={state.email.isActive}
            onFocus={() => onFocus("email")}
            onBlur={() => onBlur("email")}
          />
        </FormInputLabel>

        <FormInputLabel
          hasWarning={state.password.doWarn}
          infoText={state.password.warnings}
          text={stringRes["login.modal.password.label"]}>
          <FormInputPassword
            onEnterKey={onContinue}
            onChange={(e) => onPasswordChange(e.target.value, email)}
            value={password}
            isValid={!state.password.hasBeenTouched || state.password.isValid}
            isActive={state.password.isActive}
            onFocus={() => onFocus("password")}
            onBlur={() => onBlur("password")}
          />
        </FormInputLabel>
      </div>
    </Fragment>
  );
}

const MIN_PASS_CHARS = 8;
const MIN_PASS_SCORE = 2;

const PASS_SCORE = [
  {
    strength: "register.modal.password.strength.weak",
    barLen: "20%",
    color: "var(--error)",
  },
  {
    strength: "register.modal.password.strength.weak",
    barLen: "40%",
    color: "var(--error)",
  },
  {
    strength: "register.modal.password.strength.medium",
    barLen: "60%",
    color: "#FF9900",
  },
  {
    strength: "register.modal.password.strength.strong",
    barLen: "80%",
    color: "#04b1a7",
  },
  {
    strength: "register.modal.password.strength.maxstrong",
    barLen: "100%",
    color: "#04b1a7",
  },
];

export function RegisterByEmail({ onClose, state }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const { openModal } = useModalContext();
  const { signup } = useAuth();

  const [fieldState, setFieldState] = useState({
    email: {
      isValid: false,
      isActive: false,
      hasBeenTouched: false,
      doWarn: false,
      warnings: stringRes["register.modal.warning.email"],
    },
    password: {
      isValid: false,
      isActive: false,
      hasBeenTouched: false,
      doWarn: false,
      warnings: stringRes["register.modal.warning.password.length"],
    },
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passScore, setPassScore] = useState(-1);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [showPassHint, setShowPassHint] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onCreateAccount = (email) => {
    // go to verify email
    console.log("on create acc called");
    openModal(MODAL_TYPE.verifyEmail, { email }, onClose);
  };

  const onContinueHandler = () => {
    if (
      email &&
      password &&
      EMAIL_PATTERN.test(email) &&
      password.length >= MIN_PASS_CHARS &&
      passScore >= MIN_PASS_SCORE
    ) {
      setIsAuthorizing(true);
      signup(
        email,
        password,
        () => onCreateAccount(email),
        (errorCode) => {
          setIsAuthorizing(false);
          if (errorCode === "auth/weak-password") {
            setErrorMessage(stringRes["register.modal.error.weakPassword"]);
          } else if (errorCode === "auth/email-already-in-use") {
            setErrorMessage(stringRes["register.modal.error.emailInUse"]);
          }
        }
      );
    }
  };

  const onEmailChange = (value) => {
    setEmail(value);
    let isValidEmail = EMAIL_PATTERN.test(value);
    setFieldState((prev) => {
      return {
        ...prev,
        email: {
          ...prev["email"],
          isValid: isValidEmail,
          doWarn: isValidEmail ? false : prev["email"].doWarn,
        },
      };
    });
  };

  const onPasswordChange = (value, email) => {
    setPassword(value);
    const passChecker = zxcvbn(value, email ? [email] : []);
    console.log(passChecker);
    setPassScore(value.length < MIN_PASS_CHARS ? 0 : passChecker.score);
    setShowPassHint(
      value.length > 0 && (passChecker.score < MIN_PASS_SCORE || value.length < MIN_PASS_CHARS)
    );
    const isValidPass = passChecker.score >= MIN_PASS_SCORE && value.length >= MIN_PASS_CHARS;

    setFieldState((prev) => {
      return {
        ...prev,
        password: {
          ...prev["password"],
          isValid: isValidPass,
          doWarn: value.length >= MIN_PASS_CHARS ? false : prev["password"].doWarn,
        },
      };
    });
  };

  const onFocusField = (field) => {
    setFieldState((prev) => {
      return {
        ...prev,
        [field]: { ...prev[field], isActive: true, hasBeenTouched: true },
      };
    });
  };

  const onBlurField = (field) => {
    setFieldState((prev) => {
      return {
        ...prev,
        [field]: {
          ...prev[field],
          isActive: false,
          doWarn: !prev[field].isValid && prev[field].hasBeenTouched,
        },
      };
    });
  };

  const termsLinks = [
    {
      name: stringRes["booking.checkout.payment.terms.conditions"],
      strVar: "##T_AND_C##",
      link: "/terms",
    },
    {
      name: stringRes["booking.checkout.payment.terms.privacy"],
      strVar: "##PRIVACY_POLICY##",
      link: "/privacy",
    },
  ];

  const termsAndConditions = encloseLinksInHTMLTags(
    stringRes["register.modal.terms"],
    termsLinks
  );

  const componentProps = {
    onContinue: onContinueHandler,
    email,
    password,
    onPasswordChange,
    onEmailChange,
    state: fieldState,
    onFocus: (field) => onFocusField(field),
    onBlur: (field) => onBlurField(field),
  };

  const innerContent = (
    <Fragment>
      <div className={classes.image}>
        <img src={illustration} alt="travellers walking, airport in the background" />
      </div>
      <h1>{stringRes["register.modal.title"]}</h1>
      <p className={classes.desc}>{stringRes["register.modal.description"]}</p>
      {errorMessage && (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          className={classes.error}>
          <WarningIcon size={16} />
          {errorMessage}
        </motion.div>
      )}
      <CredentialInputComponent {...componentProps} />
      {passScore > -1 && password && (
        <div
          style={{ marginBottom: showPassHint ? "0.875rem" : 0 }}
          className={`${classes.indicator} ${classes["weak"]}`}>
          <span className={classes.bar}>
            <motion.span
              style={{ backgroundColor: PASS_SCORE[passScore].color }}
              animate={{ width: PASS_SCORE[passScore].barLen }}></motion.span>
          </span>
          <span style={{ color: PASS_SCORE[passScore].color }} className={classes.text}>
            <b>{stringRes[PASS_SCORE[passScore].strength]}</b>
          </span>
        </div>
      )}
      {showPassHint && (
        <FormInfoElement>
          <span className={classes.passwordInfo}>
            <b>{stringRes["register.modal.password.weak.title"]}</b>
          </span>
          <span style={{ wordWrap: "anywhere" }}>
            {stringRes["register.modal.password.weak.info"]}
          </span>
        </FormInfoElement>
      )}
      <div className={classes.actions}>
        <Button secondary onClick={onClose} name={stringRes["register.modal.back.button"]} />
        <Button
          opts={{ minWidth: "11rem" }}
          onClick={onContinueHandler}
          name={
            isAuthorizing ? (
              <DotPulseLoader opacity={0.4} color="#fff" />
            ) : (
              stringRes["register.modal.create.button"]
            )
          }
        />
      </div>
      <div className={classes.terms}>
        <Interweave content={termsAndConditions} transform={transformNewTabLinks} />
      </div>
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color={"var(--ui-5)"} />
          </button>
          {innerContent}
        </div>
      </DesktopModal>
    );
  }
}
