import { useDispatch } from "react-redux";
import { Link, useMatch } from "react-router-dom";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import BookingStepsIndicator from "src/components/features/booking/BookingStepsIndicator/BookingStepsIndicator";
import { TopRightMenu } from "src/components/shared/navigation/TopNavigation/TopNavigation";
import { BOOKING_STEP } from "src/constants";
import { useBookingFlow } from "src/context/booking-flow-context";
import { useLocaleContext } from "src/context/locale-context";
import { filterSortActions } from "src/store/filter-sort";
import classes from "./DesktopBookingHeader.module.css";

function DesktopBookingHeader() {
  const dispatch = useDispatch();
  const { getFirstPageId } = useBookingFlow();

  const match = useMatch("/booking/:bookingStep");
  const { bookingStep } = match?.params || {};
  const bookingPage = Object.values(BOOKING_STEP).find((v) => v.path === bookingStep);

  const onClickLogoHandler = () => {
    dispatch(filterSortActions.clearAllFilters());
  };

  return (
    <header className={classes.container}>
      <PaddedView>
        <Link
          to={"/"}
          replace={bookingPage?.id !== getFirstPageId()}
          onClick={onClickLogoHandler}>
          <img
            className={classes.logo}
            alt="logo"
            src={
              `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
              `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/static/` +
              `${window.SERVER_DATA.REACT_APP_TENANT_NAME}/en/assets/images/logo.svg`
            }
          />
        </Link>
        <BookingStepsIndicator />
        <TopRightMenu />
      </PaddedView>
    </header>
  );
}

export function DesktopPaymentLinkHeader({ bookingNumber }) {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();

  const onClickLogoHandler = () => {
    dispatch(filterSortActions.clearAllFilters());
  };

  return (
    <header className={classes.container}>
      <PaddedView>
        <Link to={"/"} replace onClick={onClickLogoHandler}>
          <img
            className={classes.logo}
            alt="logo"
            src={
              `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
              `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/static/` +
              `${window.SERVER_DATA.REACT_APP_TENANT_NAME}/en/assets/images/logo.svg`
            }
          />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "0.375rem",
            paddingLeft: "1.25rem",
            borderLeft: "1px #d9d9d9 solid",
            fontSize: "var(--f-size-14px)",
            fontFamily: "var(--font-medium)",
            fontWeight: "var(--medium-w)",
            color: "var(--ui-5)",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "98%",
          }}>
          <p>{`${stringRes["booking.indicator.orderID"]} ${bookingNumber}`}</p>
        </div>
        <TopRightMenu />
      </PaddedView>
    </header>
  );
}

export default DesktopBookingHeader;
