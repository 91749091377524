import { Interweave } from "interweave";
import React from "react";
import { STATIC_ELEMENT } from "src/constants/static";
import { useDeviceContext } from "src/context/device-context";
import useStaticContent from "src/hooks/useStaticContent";
import "./Footer.css";

function MobileFooter({ template }) {
  return (
    <div style={{ display: "contents" }} className="footer-mobile">
      <Interweave
        content={template}
        transform={(node, children) => {
          if (node.classList.contains("footer-heading")) {
            return React.createElement(
              node.tagName.toLowerCase(),
              {
                className: "footer-heading collapsed",
                onClick: (e) => {
                  const willExpand = e.currentTarget.classList.contains("collapsed");
                  if (willExpand) {
                    const footer = document.querySelector("footer");
                    const otherHeadings = footer.querySelectorAll(".footer-heading");
                    otherHeadings.forEach((el) => el.classList.add("collapsed"));
                  }
                  e.currentTarget.classList.toggle("collapsed");
                },
              },
              children
            );
          }
        }}
      />
    </div>
  );
}

function Footer() {
  const { isMobile } = useDeviceContext();
  const { html: template } = useStaticContent(STATIC_ELEMENT.footer);

  if (isMobile) return <MobileFooter template={template} />;

  return (
    <div
      style={{ display: "contents" }}
      className={isMobile ? "footer-mobile" : ""}
      dangerouslySetInnerHTML={{ __html: template }}></div>
  );
}

export default Footer;
