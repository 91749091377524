import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { BAG_WEIGHT_CATEGORY } from "src/constants";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { useDeviceContext } from "src/context/device-context";
import { updateCheckedBaggageInclusion } from "src/store/actions";
import { selectTravelPackage } from "src/store/booking";
import { orderActions } from "src/store/order";
import {
  BAGGAGE,
  hasAnyRegisteredBagData,
  hasFreeBaggageIncluded,
  hasRegisteredBaggageType,
} from "src/utils/baggage-utils";
import { LostBaggageProtection } from "../LostBaggageProtection/LostBaggageProtection";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./BaggageSelection.module.css";
import { CabinBaggageOption } from "./elements/CabinBaggageOption";
import { RegisteredBaggageOption } from "./elements/RegisteredBaggageOption";
import { BAG_IMG_MAP, createBagSelectionObject } from "./utils";

export function BaggageSelection({ passengerId, baggageData, paxData }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  const travelPackage = useSelector(selectTravelPackage);
  const isProtectionOn = baggageData?.protection.serviceId === CATALOGUE_IDS.BAG_PROTECTION;

  const [state, setState] = useState({
    showProtectionService: isProtectionOn || baggageData?.checked.serviceId,
    showBaggageArea: true,
  });

  const skipBagRef = useRef();

  const onSelectCabinBaggage = (isChecked, serviceId) => {
    if (isChecked) {
      dispatch(orderActions.changeCabinBaggageType({ passengerId, serviceId }));
    }
  };

  const onSelectCheckedBaggage = (isChecked, serviceId) => {
    if (isChecked) {
      dispatch(orderActions.changeCheckedBaggageType({ passengerId, serviceId }));
      setState((prev) => {
        return { ...prev, showProtectionService: true };
      });
    }
  };

  const onSkipCheckedBaggage = (id, doSkip) => {
    dispatch(updateCheckedBaggageInclusion(passengerId, !doSkip));
    setState((prev) => {
      return { ...prev, showBaggageArea: !doSkip, showProtectionService: false };
    });
  };

  const cabinProps = {
    passengerId: passengerId,
    selected: baggageData?.cabin.serviceId,
    onSelect: onSelectCabinBaggage,
  };

  const enabledBaggage = useMemo(() => {
    return createBagSelectionObject(travelPackage);
  }, [travelPackage]);

  useEffect(() => {
    if (!hasAnyRegisteredBagData(travelPackage)) {
      onSkipCheckedBaggage("", true);
    }
    if (baggageData?.cabin.serviceId === SERVICE_TYPE.NOT_SELECTED) {
      onSelectCabinBaggage(
        true,
        enabledBaggage.coBundleData.isFree
          ? CATALOGUE_IDS.cob_baggage
          : CATALOGUE_IDS.pi_baggage
      );
    }
    if (
      baggageData?.checked.serviceId === SERVICE_TYPE.NOT_SELECTED &&
      hasFreeBaggageIncluded(travelPackage.baggage, BAGGAGE.checked)
    ) {
      onSelectCheckedBaggage(
        true,
        enabledBaggage.regDoubleData.isFree
          ? CATALOGUE_IDS.r_baggage_double
          : enabledBaggage.regLargeData.isFree
          ? CATALOGUE_IDS.r_baggage_larger
          : CATALOGUE_IDS.r_baggage_def
      );
    }
  }, []);

  const checkedProps = {
    passengerId: passengerId,
    selected: baggageData?.checked.serviceId,
    onSelect: onSelectCheckedBaggage,
    isProtectionOn: isProtectionOn,
  };

  return (
    <div className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
      <div
        className={`${classes.baggageOpts} ${
          !enabledBaggage.coBundleData.isFree
            ? classes.withPi
            : enabledBaggage.coBundleData.hasUpgrade
            ? classes.coUpgrage
            : classes.onlyCo
        }`}>
        <h2>{stringRes["booking.form.baggage.cabin.subtitle"]}</h2>
        {!enabledBaggage.coBundleData.isFree && (
          <CabinBaggageOption
            {...cabinProps}
            images={BAG_IMG_MAP[CATALOGUE_IDS.pi_baggage]}
            serviceId={CATALOGUE_IDS.pi_baggage}
            dimensions={[enabledBaggage.piOnlyData.dim]}
            description={`${enabledBaggage.piOnlyData.weight} ${stringRes["booking.form.baggage.cabin.personal.info"]}`}
          />
        )}
        <CabinBaggageOption
          {...cabinProps}
          images={BAG_IMG_MAP[CATALOGUE_IDS.cob_baggage].slice(
            0,
            enabledBaggage.coBundleData.fastTrack ? 3 : 2
          )}
          serviceId={CATALOGUE_IDS.cob_baggage}
          dimensions={[
            enabledBaggage.coBundleData.piData.dim,
            enabledBaggage.coBundleData.coData.dim,
            stringRes["booking.form.baggage.priority"],
          ].slice(0, enabledBaggage.coBundleData.fastTrack ? 3 : 2)}
          description={
            `${stringRes["booking.form.baggage.cabin.personal"]} (${enabledBaggage.coBundleData.piData.weight})` +
            ` + ${stringRes["booking.form.baggage.cabin"]} (${enabledBaggage.coBundleData.coData.weight})` +
            (enabledBaggage.coBundleData.fastTrack
              ? ` + ${stringRes["booking.form.baggage.priority"]}`
              : "")
          }
          isPopular
        />
        {enabledBaggage.coBundleData.isFree && enabledBaggage.coBundleData.hasUpgrade && (
          <CabinBaggageOption
            {...cabinProps}
            images={BAG_IMG_MAP[CATALOGUE_IDS.cob_baggage_upgrade].slice(
              0,
              enabledBaggage.coBundleUpgradeData.fastTrack ? 3 : 2
            )}
            serviceId={CATALOGUE_IDS.cob_baggage_upgrade}
            dimensions={[
              enabledBaggage.coBundleUpgradeData.piData.dim,
              enabledBaggage.coBundleUpgradeData.coData.dim,
              stringRes["booking.form.baggage.priority"],
            ].slice(0, enabledBaggage.coBundleUpgradeData.fastTrack ? 3 : 2)}
            description={
              `${stringRes["booking.form.baggage.cabin.personal"]} (${enabledBaggage.coBundleUpgradeData.piData.weight})` +
              ` + ${stringRes["booking.form.baggage.cabin"]} (${enabledBaggage.coBundleUpgradeData.coData.weight})` +
              (enabledBaggage.coBundleUpgradeData.fastTrack
                ? ` + ${stringRes["booking.form.baggage.priority"]}`
                : "")
            }
            upgradeWeight={enabledBaggage.coBundleUpgradeData.coData.upgradeWeight}
          />
        )}
      </div>
      <AnimatePresence>
        {!paxData?.skipCheckedBaggage && hasAnyRegisteredBagData(travelPackage) && (
          <Fragment>
            <motion.div className={classes.baggageOpts}>
              <h2>{stringRes["booking.form.baggage.checked.subtitle"]}</h2>

              {hasRegisteredBaggageType(travelPackage, false, BAG_WEIGHT_CATEGORY.default) &&
                !enabledBaggage.regDefaultData.disabled && (
                  <RegisteredBaggageOption
                    {...checkedProps}
                    serviceId={CATALOGUE_IDS.r_baggage_def}
                    dimensions={[enabledBaggage.regDefaultData.dim]}
                    description={enabledBaggage.regDefaultData.weight}
                  />
                )}

              {hasRegisteredBaggageType(travelPackage, false, BAG_WEIGHT_CATEGORY.larger) &&
                !enabledBaggage.regLargeData.disabled && (
                  <RegisteredBaggageOption
                    {...checkedProps}
                    serviceId={CATALOGUE_IDS.r_baggage_larger}
                    dimensions={[enabledBaggage.regLargeData.dim]}
                    description={enabledBaggage.regLargeData.weight}
                  />
                )}

              {hasRegisteredBaggageType(travelPackage, true) && (
                <RegisteredBaggageOption
                  {...checkedProps}
                  serviceId={CATALOGUE_IDS.r_baggage_double}
                  dimensions={[enabledBaggage.regDoubleData.dim]}
                  description={`2x ${enabledBaggage.regDoubleData.weight}`}
                />
              )}
            </motion.div>

            {state.showProtectionService && (
              <LostBaggageProtection
                passengerId={passengerId}
                isProtectionOn={isProtectionOn}
              />
            )}
          </Fragment>
        )}
      </AnimatePresence>

      {hasAnyRegisteredBagData(travelPackage) && (
        <div className={classes.skipBaggage} ref={skipBagRef}>
          <Checkbox
            id={"no-checked-baggage" + passengerId}
            isChecked={!!paxData?.skipCheckedBaggage}
            label={stringRes["booking.form.baggage.without"]}
            onChange={onSkipCheckedBaggage}
            name={"no-checked-baggage-option" + passengerId}
            isSameAsMobile
            checkMarkColor="#fff"
          />
        </div>
      )}
    </div>
  );
}
