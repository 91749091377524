import axios from "axios";
import { getSelectedLocale } from "src/utils/storage-utils";

export const BASE_URL = `https://${window.SERVER_DATA.REACT_APP_API_URL}`;
// export const BASE_URL = "http://localhost:8080";

export const axiosInstanceWCompany = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: { "X-Company-Name": window.SERVER_DATA.REACT_APP_TENANT_NAME },
});

axiosInstanceWCompany.interceptors.request.use((config) => {
  config.headers["X-Language"] = getSelectedLocale();
  return config;
});
