import classes from "./LoginByEmail.module.css";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { useDeviceContext } from "src/context/device-context";
import { Fragment, useState } from "react";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "../Modal/Modal";
import DesktopModal from "../Modal/DesktopModal";
import { useLocaleContext } from "src/context/locale-context";
import {
  FormInputLabel,
  FormInputPassword,
  FormInputText,
} from "src/components/shared/input/FormInput/FormInput";
import { EMAIL_PATTERN } from "src/constants/validation";
import Button from "src/components/UI/Button/Button";
import { useAuth } from "src/context/auth-context";
import { LOGIN_VENDOR } from "src/constants";
import { WarningIcon } from "src/components/UI/Icon/WarningIcon";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import { ResetLinkSent } from "./ResetLinkSent";
import illustration from "src/assets/img/illustrations/illustr3.png";

function EmailInputComponent({ onContinue, email, onEmailChange, state, onFocus, onBlur }) {
  const { stringRes } = useLocaleContext();

  const onContinueHandler = () => {
    if (email && EMAIL_PATTERN.test(email)) {
      onContinue();
    }
  };

  return (
    <Fragment>
      <div className={classes.email}>
        <FormInputLabel text={stringRes["login.modal.email.label"]}>
          <FormInputText
            placeholder={stringRes["login.modal.email.placeholder"]}
            onChange={(e) => onEmailChange(e.target.value)}
            value={email}
            isValid={!state.hasBeenTouched || state.isValid}
            isActive={state.isActive}
            onFocus={onFocus}
            onBlur={onBlur}
            onEnterKey={onContinueHandler}
            name="email"
            autocomplete="on"
          />
        </FormInputLabel>
      </div>
      <Button
        wide
        onClick={onContinueHandler}
        name={stringRes["login.modal.email.continue.button"]}
      />
    </Fragment>
  );
}

function PasswordInputComponent({
  onLogin,
  onBackToEmail,
  email,
  password,
  onPasswordChange,
  state,
  onFocus,
  onBlur,
  isAuthorizing,
  errorMessage,
  onForgotPassword,
}) {
  const { stringRes } = useLocaleContext();

  const onLoginHandler = () => {
    if (password && state.isValid) onLogin();
  };

  return (
    <Fragment>
      <div className={classes.cred}>
        <p className={classes.desc}>{stringRes["login.modal.password.desc"]}</p>
        {errorMessage && (
          <div className={classes.error}>
            <WarningIcon size={16} />
            {errorMessage}
          </div>
        )}
        <div className={classes.email}>
          <span>{email}</span>
          <button onClick={onBackToEmail}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3337_16199)">
                <path
                  d="M17.118 0.881755C16.5447 0.333994 15.7824 0.0283203 14.9895 0.0283203C14.1966 0.0283203 13.4343 0.333994 12.861 0.881755L0 13.7428V17.9998H4.257L17.118 5.13875C17.6817 4.57381 17.9983 3.80833 17.9983 3.01025C17.9983 2.21218 17.6817 1.4467 17.118 0.881755ZM3.3255 15.7498H2.25V14.6743L11.7397 5.18526L12.8153 6.26L3.3255 15.7498Z"
                  fill="#007A69"
                />
              </g>
              <defs>
                <clipPath id="clip0_3337_16199">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <FormInputLabel text={stringRes["login.modal.password.label"]}>
          <FormInputPassword
            onEnterKey={onLoginHandler}
            onChange={(e) => onPasswordChange(e.target.value)}
            value={password}
            isValid={!state.hasBeenTouched || state.isValid}
            isActive={state.isActive}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </FormInputLabel>
      </div>
      <button onClick={onForgotPassword} className={classes.forgot}>
        {stringRes["login.modal.password.forgot"]}
      </button>
      <Button
        disabled={isAuthorizing}
        onClick={onLoginHandler}
        fontclass={classes.signin}
        name={
          isAuthorizing ? (
            <DotPulseLoader opacity={0.4} color="#fff" />
          ) : (
            stringRes["login.modal.email.signin.button"]
          )
        }
      />
    </Fragment>
  );
}

const LOGIN_FLOW_STEP = [EmailInputComponent, PasswordInputComponent, ResetLinkSent];

export function LoginByEmail({ modalId, onClose, state, onAfterLogin }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const { openModal } = useModalContext();
  const { login, triggerPasswordReset } = useAuth();

  const [fieldState, setFieldState] = useState({
    email: { isValid: false, isActive: false, hasBeenTouched: false },
    password: { isValid: true, isActive: false, hasBeenTouched: false },
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [current, setCurrentStep] = useState(0);
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onEmailChange = (value) => {
    setEmail(value);
    let isValidEmail = EMAIL_PATTERN.test(value);
    setFieldState((prev) => {
      return { ...prev, email: { ...prev["email"], isValid: isValidEmail } };
    });
  };

  const onPasswordChange = (value) => {
    setPassword(value);
  };

  const onFocusField = (field) => {
    setFieldState((prev) => {
      return {
        ...prev,
        [field]: { ...prev[field], isActive: true, hasBeenTouched: true },
      };
    });
  };

  const onBlurField = (field) => {
    setFieldState((prev) => {
      return { ...prev, [field]: { ...prev[field], isActive: false } };
    });
  };

  const onForgotPasswordHandler = () => {
    triggerPasswordReset(
      email,
      () => {
        openModal(MODAL_TYPE.resetLinkSent, {}, onClose);
      },
      (err) => {
        setErrorMessage("Something went wrong. Try again later");
      }
    );
  };

  const componentProps = [
    {
      onContinue: () => setCurrentStep((prev) => prev + 1),
      email,
      onEmailChange,
      state: fieldState.email,
      onFocus: () => onFocusField("email"),
      onBlur: () => onBlurField("email"),
    },
    {
      onLogin: () => {
        setIsAuthorizing(true);
        login(
          LOGIN_VENDOR.email,
          email,
          password,
          () => {
            onClose();
            if (onAfterLogin) onAfterLogin();
          },
          (errorCode) => {
            setIsAuthorizing(false);
            let errMsg = stringRes["login.modal.error.credentials"];
            setErrorMessage(errMsg);
          }
        );
      },
      onBackToEmail: () => {
        setPassword("");
        setErrorMessage("");
        setCurrentStep(0);
      },
      onForgotPassword: onForgotPasswordHandler,
      isAuthorizing,
      email,
      password,
      state: fieldState.password,
      onPasswordChange,
      onFocus: () => onFocusField("password"),
      onBlur: () => onBlurField("password"),
      errorMessage,
    },
  ];

  const CurrentComponent = LOGIN_FLOW_STEP[current];
  const innerContent = (
    <Fragment>
      <div className={classes.image}>
        <img src={illustration} alt="travellers walking, airport in the background" />
      </div>
      <h1>{stringRes["login.modal.email.title"]}</h1>
      <CurrentComponent {...componentProps[current]} />
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal
        classBody={classes.body}
        modalId={modalId}
        isClosing={state.isClosing}
        onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color={"var(--ui-5)"} />
          </button>
          {innerContent}
        </div>
      </DesktopModal>
    );
  }
}
