import { STOPS } from "src/constants";
import { getTimeInMinutes } from "src/utils/date-utils";
import { getPricePerPerson } from "./results-utils";
import { BAGGAGE, hasFreeBaggageIncluded } from "./baggage-utils";

export function getPackageArrivalTime(pckg) {
  let lastSeg = pckg.segments[pckg.segments.length - 1];
  return getTimeInMinutes(lastSeg.arr.time);
}

export function getPackageDepartureTime(pckg) {
  return getTimeInMinutes(pckg.segments[0].dep.time);
}

export function getCheapestPrice(data, startIdx) {
  const prices = data.map((flightPackage) => getPricePerPerson(flightPackage));
  let minPrice = prices[startIdx];
  let cheapestIdx = startIdx;
  for (let i = startIdx + 1; i < prices.length; i++) {
    if (prices[i] < minPrice) {
      minPrice = prices[i];
      cheapestIdx = i;
    }
  }
  return { minPrice, cheapestIdx };
}

export function attachResultTags(data) {
  console.log("attachResultTags");

  /**CHECK IF SOME ALREADY TAGGED */
  data
    .filter((item) => item.isBest || item.isCheapest)
    .forEach((item) => {
      delete item.isBest;
      delete item.isCheapest;
    });

  /**ASSIGN TAGS */
  if (data.length > 0) {
    data.sort(sortByBestScore);
    data[0].isBest = true;

    data.sort(sortByPrice);
    data[0].isCheapest = true;
    if (data[0].isBest && data.length > 1) {
      data[1].isCheapest = true;
    }
  }

  return data;
}

function findMin(arr) {
  if (arr.length === 0) {
    return -1;
  }

  let min = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < min) {
      min = arr[i];
    }
  }

  return min;
}

function findMax(arr) {
  if (arr.length === 0) {
    return -1;
  }

  let max = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i];
    }
  }

  return max;
}

export function getFilterValues(data) {
  const filterValues = {
    minPrice: 0,
    maxPrice: 0,
    maxDuration: 0,
    minStopover: 0,
    maxStopover: 0,
    airlines: [],
    airports: [],
    departureStartTime: {},
    departureEndTime: {},
    returnStartTime: { min: 0, max: 1439 },
    returnEndTime: { min: 0, max: 1439 },
  };

  /** PRICE FILTER VALUES */
  const prices = data.map((flightPckg) => getPricePerPerson(flightPckg));
  filterValues.minPrice = findMin(prices);
  filterValues.maxPrice = findMax(prices);

  const flights = data.flatMap((flightPckg) => flightPckg.segments);

  /** AIRLINES & AIRPORT FILTER VALUES */
  filterValues.airlines = [...new Set(flights.map((fl) => fl.carrier.title))];
  filterValues.airports = [
    ...new Set(
      flights.flatMap((fl) => [
        `${fl.dep.airport.title} (${fl.dep.airport.code})`,
        `${fl.arr.airport.title} (${fl.arr.airport.code})`,
      ])
    ),
  ];

  /** DURATION FILTER VALUES (per flight group) */
  const routeDurations = data.flatMap((flightPckg) => {
    // the package can be broken doewn into groups of chained flights
    // extract durations from flights that mark the start of each chain
    let duration = [];
    flightPckg.segments_direction.forEach((segDir) => {
      let chainStart = segDir[0];
      duration.push(flightPckg.segments[chainStart].route_duration);
    });
    return duration; // ex: [ 1000, 1400, 1300 ]
  });
  filterValues.maxDuration = Math.ceil(findMax(routeDurations) / 60);

  /** STOPOVER FILTER VALUES */
  const stopovers = flights
    .filter((fl) => "transfer" in fl.duration)
    .map((fl) => fl.duration.transfer.total_min);
  filterValues.minStopover = Math.floor(findMin(stopovers) / 60);
  filterValues.maxStopover = Math.ceil(findMax(stopovers) / 60);

  return filterValues;
}

export function getSortValues(data) {
  const sortValues = {
    best: { score: -1 },
    cheapest: { score: -1 },
    fastest: { score: -1 },
    arrival: { time: -1, score: -1 },
    departure: { time: -1, score: -1 },
  };

  if (!data || data.length < 1) {
    return sortValues;
  }

  const firstPackage = data[0];
  const firstElDuration = firstPackage.duration;
  const firstElPrice = getPricePerPerson(firstPackage);

  for (const key in sortValues) {
    if (sortValues.hasOwnProperty(key)) {
      const sortValue = sortValues[key];
      sortValue.price = firstElPrice;
      sortValue.duration = firstElDuration;
      sortValue.ref = firstPackage;
      sortValue.score = firstPackage.score;

      if ("time" in sortValue) {
        sortValue.time =
          key === "arrival"
            ? getPackageArrivalTime(firstPackage)
            : getPackageDepartureTime(firstPackage);
      }
    }
  }

  data.forEach((p) => {
    let packageDuration = p.duration;
    let packagePrice = getPricePerPerson(p);
    let packageDepTime = getPackageDepartureTime(p);
    let packageArrTime = getPackageArrivalTime(p);
    let packageScore = p.score;

    // compare speed
    if (
      packageDuration < sortValues.fastest.duration ||
      (packageDuration === sortValues.fastest.duration &&
        packageScore < sortValues.fastest.score)
    ) {
      sortValues.fastest.duration = packageDuration;
      sortValues.fastest.price = packagePrice;
      sortValues.fastest.ref = p;
      sortValues.fastest.score = packageScore;
    }

    // compare price
    if (
      packagePrice < sortValues.cheapest.price ||
      (packagePrice === sortValues.cheapest.price && packageScore < sortValues.cheapest.score)
    ) {
      sortValues.cheapest.duration = packageDuration;
      sortValues.cheapest.price = packagePrice;
      sortValues.cheapest.ref = p;
      sortValues.cheapest.score = packageScore;
    }

    // compare arr time
    if (
      packageDepTime < sortValues.departure.time ||
      (packageDepTime === sortValues.departure.time &&
        packageScore < sortValues.departure.score)
    ) {
      sortValues.departure.duration = packageDuration;
      sortValues.departure.price = packagePrice;
      sortValues.departure.ref = p;
      sortValues.departure.time = packageDepTime;
      sortValues.departure.score = packageScore;
    }

    // compare dep time
    if (
      packageArrTime < sortValues.arrival.time ||
      (packageArrTime === sortValues.arrival.time && packageScore < sortValues.arrival.score)
    ) {
      sortValues.arrival.duration = packageDuration;
      sortValues.arrival.price = packagePrice;
      sortValues.arrival.ref = p;
      sortValues.arrival.time = packageArrTime;
      sortValues.arrival.score = packageScore;
    }

    // compare score
    if (packageScore < sortValues.best.score) {
      sortValues.best.duration = packageDuration;
      sortValues.best.price = packagePrice;
      sortValues.best.ref = p;
      sortValues.best.score = packageScore;
    }
  });

  return sortValues;
}

export function isInRange(min, max, target) {
  return min <= target && target <= max;
}

function getStopTargetNum(value) {
  switch (value) {
    case STOPS.direct:
      return 0;
    case STOPS.one:
      return 1;
    case STOPS.two:
      return 2;
  }
}

export function getFilterMatches(tripPackage, activeFilters, isRoundtrip) {
  let matches = 0;
  let doesBaggageMatch = true;

  /** BAGS BASED FILTERING */
  let cabinBagsDoMatch = true;
  if (activeFilters.cabinBaggage) {
    const cabinBagsFree = hasFreeBaggageIncluded(tripPackage.baggage, BAGGAGE.cabin)
      ? tripPackage.baggage.co_baggage[0].piece
      : 0;
    cabinBagsDoMatch = cabinBagsFree >= activeFilters.cabinBaggage;
  }

  let registeredBagsDoMatch = true;
  if (activeFilters.checkedBaggage) {
    const checkedBagsFree = hasFreeBaggageIncluded(tripPackage.baggage, BAGGAGE.checked)
      ? tripPackage.baggage.r_baggage[0].piece
      : 0;
    registeredBagsDoMatch = checkedBagsFree >= activeFilters.checkedBaggage;
  }

  doesBaggageMatch = cabinBagsDoMatch && registeredBagsDoMatch;

  /** FLIGHT STOPS NUMBER BASED FILTERING */
  if (activeFilters.stops) {
    const stopTarget = getStopTargetNum(activeFilters.stops);
    const packageStops = tripPackage.segments_direction.map((group) => group.length - 1);
    const allMatch = packageStops.every((groupStopNum) => groupStopNum <= stopTarget);
    matches += allMatch ? 1 : 0;
  }

  /** PRICE BASED FILTERING */
  if (activeFilters.price) {
    matches += isInRange(
      activeFilters.price[0],
      activeFilters.price[1],
      getPricePerPerson(tripPackage)
    )
      ? 1
      : 0;
  }

  /** FLIGHT GROUP/CHAIN DURATION BASED FILTERING */
  if (activeFilters.duration) {
    let flightChainStart = tripPackage.segments_direction.map((group) => group[0]);
    const packageTravelTimes = flightChainStart.map(
      (chainStart) => tripPackage.segments[chainStart].route_duration
    );
    const allMatch = packageTravelTimes.every(
      (flightChainTravelTime) => flightChainTravelTime <= activeFilters.duration * 60
    );
    matches += allMatch ? 1 : 0;
  }

  /** FLIGHT STOP DURATION BASED FILTERING */
  if (activeFilters.stopover) {
    const packageStopovers = tripPackage.segments
      .filter((fl) => "transfer" in fl.duration)
      .map((fl) => fl.duration.transfer.total_min);
    const allMatch = packageStopovers.every((stopover) =>
      isInRange(activeFilters.stopover[0] * 60, activeFilters.stopover[1] * 60, stopover)
    );
    matches += allMatch ? 1 : 0;
  }

  /** AIRLINE BASED FILTERING */
  if (activeFilters.airline) {
    const packageAirlines = [...new Set(tripPackage.segments.map((fl) => fl.carrier.title))];
    const allMatch = packageAirlines.every((al) => activeFilters.airline.includes(al));
    matches += allMatch ? 1 : 0;
  }

  /** AIRPORT BASED FILTERING */
  if (activeFilters.airport) {
    const packageAirports = [
      ...new Set(
        tripPackage.segments.flatMap((fl) => [
          `${fl.dep.airport.title} (${fl.dep.airport.code})`,
          `${fl.arr.airport.title} (${fl.arr.airport.code})`,
        ])
      ),
    ];
    const allMatch = packageAirports.every((ap) => activeFilters.airport.includes(ap));
    matches += allMatch ? 1 : 0;
  }

  /** DEPARTURE START TIME BASED FILTERING */
  if (activeFilters.departureStartTime) {
    const depStartTime = getPackageDepartureTime(tripPackage);
    matches += isInRange(
      activeFilters.departureStartTime[0],
      activeFilters.departureStartTime[1],
      depStartTime
    )
      ? 1
      : 0;
  }

  /** DEPARTURE END TIME BASED FILTERING */
  if (activeFilters.departureEndTime) {
    const lastFlightInGroup =
      tripPackage.segments_direction[0][tripPackage.segments_direction[0].length - 1];
    const depEndTime = getTimeInMinutes(tripPackage.segments[lastFlightInGroup].arr.time);
    matches += isInRange(
      activeFilters.departureEndTime[0],
      activeFilters.departureEndTime[1],
      depEndTime
    )
      ? 1
      : 0;
  }

  /** RETURN START TIME BASED FILTERING */
  const lastChain = tripPackage.segments_direction[tripPackage.segments_direction.length - 1];
  if (activeFilters.returnStartTime) {
    if (isRoundtrip) {
      const retStartTime = getTimeInMinutes(tripPackage.segments[lastChain[0]].dep.time);
      matches += isInRange(
        activeFilters.returnStartTime[0],
        activeFilters.returnStartTime[1],
        retStartTime
      )
        ? 1
        : 0;
    } else {
      matches++;
    }
  }
  /** RETURN END TIME BASED FILTERING */
  if (activeFilters.returnEndTime) {
    if (isRoundtrip) {
      const retEndTime = getTimeInMinutes(
        tripPackage.segments[lastChain[lastChain.length - 1]].arr.time
      );
      matches += isInRange(
        activeFilters.returnEndTime[0],
        activeFilters.returnEndTime[1],
        retEndTime
      )
        ? 1
        : 0;
    } else {
      matches++;
    }
  }

  return { matches, doesBaggageMatch };
}

export function sortByDuration(a, b) {
  let aDuration = a.duration;
  let bDuration = b.duration;
  if (aDuration === bDuration) {
    return sortByBestScore(a, b);
  }
  return aDuration - bDuration;
}

export function sortByPrice(a, b) {
  let aPrice = getPricePerPerson(a);
  let bPrice = getPricePerPerson(b);
  if (aPrice === bPrice) {
    return sortByBestScore(a, b);
  }
  return aPrice - bPrice;
}

export function sortByEarliestArrival(a, b) {
  let aTime = getPackageArrivalTime(a);
  let bTime = getPackageArrivalTime(b);
  if (aTime === bTime) {
    return sortByBestScore(a, b);
  }
  return aTime - bTime;
}

export function sortByEarliestDeparture(a, b) {
  let aTime = getPackageDepartureTime(a);
  let bTime = getPackageDepartureTime(b);
  if (aTime === bTime) {
    return sortByBestScore(a, b);
  }
  return aTime - bTime;
}

export function sortByBestScore(a, b) {
  let aScore = a.score;
  let bScore = b.score;
  return aScore - bScore; // lower is better
}
