import { Fragment, useEffect, useState } from "react";
import classes from "./RegionalSettings.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import Button from "src/components/UI/Button/Button";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "../Modal/Modal";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import DesktopModal from "../Modal/DesktopModal";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import { FormInputDropdown } from "src/components/shared/input/FormInput/FormInput";

function SettingsDropdown({ name, value, options, onSelect, uid }) {
  const transformedOpts = Object.fromEntries(options.map((c) => [c.value, c.label]));
  const [isActive, setIsActive] = useState(false);
  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (isInit) {
      setIsActive(false);
      setIsInit(false);
    }
  }, [isActive]);

  return (
    <Fragment>
      <label htmlFor={uid}>{name}</label>
      <FormInputDropdown
        id={uid}
        onSelect={onSelect}
        options={transformedOpts}
        value={value}
        isValid
        isActive={isActive}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
      />
    </Fragment>
  );
}

function RegionalSettingsOption({ uid, name, value, choices, onSelect }) {
  return (
    <li className={classes.type}>
      <SettingsDropdown
        uid={uid}
        name={name}
        value={value}
        options={choices}
        onSelect={onSelect}
      />
    </li>
  );
}

function RegionalSettings({ onClose, state }) {
  const { currentLocale, languages, countries, currencies, stringRes, setLocale } =
    useLocaleContext();
  const { isMobile } = useDeviceContext();

  const [settings, setSettings] = useState({
    language: currentLocale,
    country: countries[0].value,
    currency: currencies[0].value,
  });

  const uids = ["select-reg-lang", "select-reg-ctry", "select-reg-curr"];

  const onSave = () => {
    setLocale(settings.language);
    onClose();
  };

  const innerContent = (
    <Fragment>
      <h1>{stringRes["regional.modal.title"]}</h1>
      <p className={classes.desc}>{`${stringRes["regional.modal.description"]}`}</p>
      <ul className={classes.settings}>
        <RegionalSettingsOption
          uid={uids[0]}
          name={stringRes["regional.modal.language.title"]}
          choices={languages}
          onSelect={(lang) => setSettings((prev) => ({ ...prev, language: lang }))}
          value={settings.language}
        />
        <RegionalSettingsOption
          uid={uids[1]}
          name={stringRes["regional.modal.country.title"]}
          choices={countries}
          onSelect={(country) => setSettings((prev) => ({ ...prev, country: country }))}
          value={settings.country}
        />
        <RegionalSettingsOption
          uid={uids[2]}
          name={stringRes["regional.modal.currency.title"]}
          choices={currencies}
          onSelect={(currency) =>
            setSettings((prev) => ({ ...prev, currency: currency }))
          }
          value={settings.currency}
        />
      </ul>

      <Button onClick={onSave} name={stringRes["regional.modal.save.button"]} />
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal
          isClosing={state.isClosing}
          clickSelectors={uids.flatMap((uid) => [`#${uid} option`, `#${uid}`])}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>
          <button type="button" className={classes.close} onClick={onClose}>
            <XIconDesktop color={"var(--ui-5)"} />
          </button>
          {innerContent}
        </div>
      </DesktopModal>
    );
  }
}

export default RegionalSettings;
