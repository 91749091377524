import { useDeviceContext } from "src/context/device-context";
import classes from "./LoadingResultsList.module.css";

function LoadingFlightInfo({ className }) {
  return (
    <div className={className}>
      <div className={classes.airlineIcon}></div>
      <div className={classes.carrierData}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

function LoadingResultsItem() {
  const { isMobile } = useDeviceContext();

  return (
    <div
      className={`${classes.resultsItem} ${isMobile ? classes.mobile : classes.desktop} ${
        classes.loading
      } theme-results-item-loading`}>
      {!isMobile && <div className="theme-results-item-decoration"></div>}
      <div className={isMobile ? classes.top : classes.left}>
        {!isMobile && <div className={classes.extraButtons}></div>}
        <LoadingFlightInfo className={classes.srcFlightInfo} />
        <LoadingFlightInfo className={classes.destFlightInfo} />
        {!isMobile && <div className={classes.detailsButton}></div>}
      </div>

      {isMobile && (
        <div className={classes.middle}>
          <div className={classes.baggageIndicators}></div>
          <div className={classes.priceBreakdown}></div>
        </div>
      )}

      <div className={isMobile ? classes.bottom : classes.right}>
        {!isMobile && <div className={classes.baggageIndicators}></div>}
        <div className={classes.seatsPrice}></div>
        <div className={classes.selectButton}></div>
      </div>
    </div>
  );
}

export function LoadingResultsList() {
  const loadingItems = [];
  for (let i = 0; i < 5; i++) {
    loadingItems.push(i);
  }

  return (
    <section className="results">
      {loadingItems.map((i) => (
        <LoadingResultsItem key={i} />
      ))}
    </section>
  );
}
