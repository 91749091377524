import { useState } from "react";
import classes from "./SearchForm.module.css";
import PassengerOptions from "./elements/PassengerOptions/PassengerOptions";
import DateSelection from "./elements/DateSelection/DateSelection";
import { AirportSelection } from "./elements/AirportSelection/AirportSelection";
import { useDeviceContext } from "src/context/device-context";
import { DesktopSearchForm } from "./DesktopSearchForm";
import { MobileSearchForm } from "./MobileSearchForm";
import { TRIP_TYPE as TRIP } from "src/constants";
import { customLog } from "src/utils/utils";
import {
  getSelectedLocale,
  markDirectFlightsRequired,
  saveTripSearchToStorage,
  setLastSavedSearch,
} from "src/utils/storage-utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { buildSearchQuery, buildBookingUrl } from "src/utils/query-utils";
import isEqual from "lodash.isequal";
import { filterSortActions } from "src/store/filter-sort";

export const getSearchFormElementContentById = (id) => {
  if (id === "dest-location" || id === "src-location") {
    return <AirportSelection isDeparture={id === "src-location"} />;
  } else if (id === "end-date" || id === "start-date") {
    return <DateSelection isDeparture={id === "start-date"} />;
  } else if (id === "passengers") {
    return <PassengerOptions />;
  }
};

function SearchForm() {
  const location = useLocation();
  const isResultsPage = location.pathname.startsWith("/search/results");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchData = useSelector((state) => state.search, shallowEqual);

  const [prevSearchParams, setPrevSearchParams] = useState({
    locations: searchData.trips.map((tr) => [tr.srcLocation.iata, tr.destLocation.iata]),
    tripType: searchData.tripType,
  });
  const { isMobile } = useDeviceContext();

  const validateForm = (tripType, searchData, onValidateFailCallback) => {
    if (
      tripType !== TRIP.multicity &&
      validateTrip(tripType, searchData.trips[0], onValidateFailCallback)
    ) {
      return true;
    } else if (tripType === TRIP.multicity) {
      let validTrips = 0;
      for (let i = 0; i < searchData.trips.length; i++) {
        if (validateTrip(tripType, searchData.trips[i], onValidateFailCallback)) {
          validTrips++;
        } else {
          return;
        }
      }
      if (searchData.trips.length === validTrips) {
        return true;
      }
    }
  };

  const validateTrip = (tripType, trip, onValidateFailCallback) => {
    let screenId = "";
    if (!trip.srcLocation.city) {
      screenId = "src-location";
    } else if (!trip.destLocation.city) {
      screenId = "dest-location";
    } else if (!trip.startDate) {
      screenId = "start-date";
    } else if (tripType === TRIP.roundtrip && !trip.endDate) {
      screenId = "end-date";
    } else {
      return true;
    }
    onValidateFailCallback(screenId, trip.id);
    return false;
  };

  const cacheSearchData = async (tripType, searchData) => {
    customLog("saving trip");
    setLastSavedSearch(searchData);
    searchData.trips.forEach((trip) => {
      saveTripSearchToStorage(trip, tripType);
    });
  };

  const preValidateForm = (tripType, searchData, onValidateFail) => {
    return validateForm(tripType, searchData, onValidateFail ? onValidateFail : () => {});
  };

  const onClickSearchHandler = (
    tripType,
    searchData,
    checkAccommodation,
    onValidateFail,
    isDirect
  ) => {
    if (validateForm(tripType, searchData, onValidateFail)) {
      const tripLocations = searchData.trips.map((tr) => [
        tr.srcLocation.iata,
        tr.destLocation.iata,
      ]);

      if (
        (prevSearchParams.locations && !isEqual(tripLocations, prevSearchParams.locations)) ||
        prevSearchParams.tripType !== tripType
      ) {
        dispatch(filterSortActions.clearAllFilters());
      }
      setPrevSearchParams({ locations: tripLocations, tripType: tripType });
      cacheSearchData(tripType, searchData);

      const q = buildSearchQuery(searchData);
      if (isDirect) markDirectFlightsRequired();
      if (isResultsPage || !checkAccommodation) {
        navigate("/search/results?" + q);
      } else {
        // and navigate to results page in new tab
        const resultsPageAddress = `${window.location.protocol}//${window.location.host}/search/results?${q}`;
        window.open(resultsPageAddress, "_blank");
      }

      // open booking.com page in background
      if (checkAccommodation) {
        customLog("checking with booking");
        const bookingUrl = buildBookingUrl(
          searchData.trips[0].destLocation.city,
          searchData.trips[0].startDate,
          searchData.trips[0].endDate,
          searchData.passengers.adult,
          searchData.passengers.child,
          searchData.passengers.infant,
          getSelectedLocale(),
          searchData.trips[0].destLocation.iata
        );
        customLog(bookingUrl);
        window.location.href = bookingUrl;
      }
      return true;
    }
    return false;
  };

  const props = {
    onSearchStart: onClickSearchHandler,
    isResultsPage: isResultsPage,
  };

  if (isResultsPage && !isMobile) {
    return <DesktopSearchForm key={"desktop-results"} {...props} />;
  } else {
    return (
      <section
        id={isMobile ? "search-form-section-mob" : "search-form-section"}
        className={`${classes.container} ${classes.search} ${
          isMobile ? "mobile" : classes.desktop
        }`}>
        {isMobile ? (
          <MobileSearchForm
            key={isResultsPage ? "mobile-results" : "mobile-homepage"}
            preValidate={preValidateForm}
            {...props}
          />
        ) : (
          <DesktopSearchForm key={"desktop-homepage"} {...props} />
        )}
      </section>
    );
  }
}

export default SearchForm;
